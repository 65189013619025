<template>
	<div>
		<v-layout wrap>
			<v-flex xs11>
				<pui-field-set :title="$t('generarlibamientotranscore.title') + ' ' + (indice + 1)">
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-number-field
								:label="$t('libramiento.pesosolicitado')"
								v-model="model.pesosolicitado"
								required
								decimals="0"
								max="8000000"
								:disabled="formDisabled"
								suffix="Kg"
								:id="'pesosolicitado' + this.indice"
								labelColumnStyles="xs12 md3"
								valueColumnStyles="xs12 md5"
							></pui-number-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-select
								v-if="modelLoaded"
								attach="idtimeslot"
								:label="$t('libramiento.idtimeslot')"
								clearable
								:disabled="formDisabled"
								v-model="model"
								required
								modelName="vluptimeslotlibramiento"
								:itemsToSelect="[{ idtimeslot: model.idtimeslot }]"
								:modelFormMapping="{ idtimeslot: 'idtimeslot' }"
								:itemValue="['idtimeslot']"
								:itemText="
									(item) =>
										`${getDateFormated(item.fecharecogida)} ${getDe()} ${item.horainicio} ${getA()} ${
											item.horafin
										} ${getDisponibles()}: ${item.disponibles}`
								"
								:fixedFilter="fixedFilterTimeslot"
								:key="timeslotKey"
								:id="'idtimeslot' + this.indice"
								:order="{ horainicio: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12 md9>
							<pui-select
								v-if="modelLoaded"
								attach="idcamion"
								:label="$t('libramiento.idcamion')"
								clearable
								:disabled="formDisabled"
								v-model="model"
								required
								modelName="vlupcamion"
								:itemsToSelect="[{ idcamion: model.idcamion }]"
								:modelFormMapping="{ idcamion: 'idcamion' }"
								:itemValue="['idcamion']"
								:itemText="(item) => `${getCamionText(item)}`"
								:id="'idcamion' + this.indice"
								:fixedFilter="fixedFilterCamion"
								detailModelName="camion"
								detailComponentName="camion"
								labelColumnStyles="xs12 md2"
								valueColumnStyles="xs12 md10"
							></pui-select>
						</v-flex>
						<v-flex xs12 md3 v-show="false">
							<pui-text-field
								v-if="modelLoaded"
								required
								:label="$t('libramiento.matricula')"
								v-model="model.matricula"
								maxlength="20"
								:disabled="formDisabled"
								:key="matricKey"
								:id="'matricula' + this.indice"
								:rules="matriculaRules"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12 md9>
							<pui-select
								v-if="modelLoaded"
								attach="idconductor"
								:label="$t('libramiento.idconductor')"
								clearable
								:disabled="formDisabled"
								v-model="model"
								modelName="vlupconductorlibbloqueado"
								:itemsToSelect="[{ usr: model.idconductor }]"
								:modelFormMapping="{ usr: 'idconductor' }"
								:itemValue="['usr']"
								:itemText="(item) => `${item.nombre}`"
								:key="conductorKey"
								:id="'idconductor' + this.indice"
								:fixedFilter="fixedFilterConductor"
								:order="{ nombre: 'asc', disabled: 'desc' }"
								labelColumnStyles="xs12 md2"
								valueColumnStyles="xs12 md10"
							></pui-select>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								v-if="modelLoaded"
								:label="$t('libramiento.remolque')"
								v-model="model.remolque"
								maxlength="20"
								:disabled="formDisabled"
								:key="remolqueKey"
								:id="'remolque' + this.indice"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md9>
							<pui-text-area
								:label="$t('libramiento.destinomercancia')"
								v-model="model.destinomercancia"
								:disabled="formDisabled"
								required
								maxlength="300"
								labelColumnStyles="xs12 md2"
								valueColumnStyles="xs12 md10"
								:id="'destinomercancia' + this.indice"
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md9>
							<pui-text-area
								:label="$t('libramiento.observaciones')"
								v-model="model.observaciones"
								:disabled="formDisabled"
								maxlength="300"
								labelColumnStyles="xs12 md2"
								valueColumnStyles="xs12 md10"
								:id="'observaciones' + this.indice"
							></pui-text-area>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-flex>
			<v-flex xs1 class="pointer" @click="removeLibramiento()" v-show="indice > 0 && !formDisabled" align-self-center>
				<v-list-item>
					<v-icon small class="trashIcon">fa fa-trash-alt</v-icon>
				</v-list-item>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
export default {
	data() {
		return {
			timeslotKey: 0,
			conductorKey: 0,
			matricKey: 0,
			remolqueKey: 0,
			matriculaRules: [(v) => this.validateMatriculaRules(v) || this.$t('libramiento.validate.matricula')]
		};
	},
	computed: {},
	methods: {
		// override
		removeLibramiento() {
			this.parentlist.splice(this.indice, 1);
			this.timeslotKey++;
			this.conductorKey++;
		},
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		},
		getDe() {
			return this.$t('libramiento.de');
		},
		getA() {
			return this.$t('libramiento.a');
		},
		getDisponibles() {
			return this.$t('libramiento.disponibles');
		},
		validateMatriculaRules(v) {
			var re = /^[a-z0-9A-Z]*$/;
			return re.test(v);
		},
		formatNumber(val) {
			if (val === null) {
				return '';
			}
			const thousandSeparator = this.$store.getters.thousandSeparator;
			const decimalSeparator = this.$store.getters.decimalSeparator;
			if (val.toString().includes('.')) {
				const parts = val.toString().split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
				return parts.join(decimalSeparator);
			}
			return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
		},
		getCamionText(item) {
			let text = '';
			text = item.matricula + (item.conductor != null ? ' - ' + item.conductor : '') + ' - ' + this.formatNumber(item.capacidad) + ' Kg';
			return text;
		}
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		parentlist: {
			type: Array,
			required: true
		},
		indice: {
			type: Number,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		fixedFilterTimeslot: {
			type: Object,
			required: false
		},
		fixedFilterConductor: {
			type: Object,
			required: false
		},
		fixedFilterCamion: {
			type: Object,
			required: false
		},
		parentModel: {
			type: Object,
			required: false
		}
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-idcamion' + this.indice, (newValCamion) => {
			var lupamodel = newValCamion.model;
			if (lupamodel !== undefined) {
				this.model.matricula = lupamodel.matricula;
				this.matricKey++;
				if (
					(this.model.remolque === null || this.model.remolque === undefined || this.model.remolque === '') &&
					(lupamodel.remolque !== undefined || lupamodel.remolque !== null)
				) {
					this.model.remolque = lupamodel.remolque;
					this.remolqueKey++;
				}
				if (
					(this.model.idconductor === null || this.model.idconductor === undefined || this.model.idconductor === '') &&
					(lupamodel.idconductor !== undefined || lupamodel.idconductor !== null)
				) {
					var filterM = {
						groups: [this.fixedFilterConductor],
						groupOp: 'and',
						rules: [{ field: 'usr', op: 'eq', data: lupamodel.idconductor }]
					};

					this.$puiRequests.postRequest('/puisearch', { model: 'vlupconductorlibbloqueado', filter: filterM, rows: 1000 }, (response) => {
						if (response && response.data && response.data.data) {
							var conductores = response.data.data;

							var countDisabled = 0;
							var reg;
							for (var i = 0, length = conductores.length; i < length; i++) {
								reg = conductores[i];
								if (reg.disabled == 1) {
									countDisabled++;
								}
							}

							if (countDisabled == 0) {
								this.model.idconductor = reg.usr;
								this.conductorKey++;
							}
						}
					});
				}
			}
		});
		/*
		this.$puiEvents.$on('onPuiSelect_selectedItems-idconductor' + this.indice, (newValConductor) => {
			var lupamodel = newValConductor.model;
			if (lupamodel !== undefined) {
				if (
					(this.model.matricula === null || this.model.matricula === undefined || this.model.matricula === '') &&
					(lupamodel.matriconductor !== undefined || lupamodel.matriconductor !== null)
				) {
					this.model.matricula = lupamodel.matriconductor;
					this.matricKey++;
				}
				if (
					(this.model.remolque === null || this.model.remolque === undefined || this.model.remolque === '') &&
					(lupamodel.remolqueconductor !== undefined || lupamodel.remolqueconductor !== null)
				) {
					this.model.remolque = lupamodel.remolqueconductor;
					this.remolqueKey++;
				}
			}
		});
		*/
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-idcamion' + this.indice);
		//this.$puiEvents.$off('onPuiSelect_selectedItems-idconductor' + this.indice);
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
		"libramiento.idtimeslot": "Time slot",
		"libramiento.pesosolicitado": "Nº kg",
		"libramiento.matricula": "License plate",
		"libramiento.remolque": "Trailer",
		"libramiento.observaciones": "Comments",
		"libramiento.idconductor": "Driver",
		"libramiento.de": "from",
		"libramiento.a": "to",
		"libramiento.disponibles": "Available voids",
		"generarlibamientotranscore.title": "Riddances"
    },
    "es": {
        "libramiento.idtimeslot": "Time slot",
		"libramiento.pesosolicitado": "Nº kg",
		"libramiento.matricula": "Matrícula",
		"libramiento.remolque": "Remolque",
		"libramiento.observaciones": "Observaciones",
		"libramiento.idconductor": "Conductor",
		"libramiento.de": "de",
		"libramiento.a": "a",
		"libramiento.disponibles": "Huecos disponibles",
		"generarlibamientotranscore.title": "Libramiento"
    },
    "ca": {
        "libramiento.idtimeslot": "Time slot",
		"libramiento.pesosolicitado": "Nº kg",
		"libramiento.matricula": "Matrícula",
		"libramiento.remolque": "Remolc",
		"libramiento.observaciones":"Observacions",
		"libramiento.idconductor": "Conductor",
		"libramiento.de": "de",
		"libramiento.a": "a",
		"libramiento.disponibles": "Buits disponibles",
		"generarlibamientotranscore.title": "Lliurament"
    }
}
</i18n>
