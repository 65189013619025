<template>
	<div>
		<camion-modals :modelName="model"></camion-modals>
		<pui-datatable :modelName="model" :actions="actions" showFilterListBtn></pui-datatable>
	</div>
</template>

<script>
/* eslint-disable */
import CamionActions from './CamionActions';
import CamionModals from './CamionModals';

export default {
	name: 'camiongrid',
	components: {
		CamionModals
	},
	data() {
		return {
			model: 'camion',
			actions: CamionActions.gridactions,
			columnDefs: {
				fecbaja: { withoutHours: true }
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>
