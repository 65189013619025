<template>
	<div>
		<v-layout wrap>
			<v-flex xs12>
				<pui-field-set :title="getTitleFieldSet1">
					<v-layout wrap>
						<v-flex xs12 md6 mx-2>
							<pui-select
								v-if="modelLoaded"
								attach="idtipousuario"
								:label="$t('usuarioaplicacion.idtipousuario')"
								clearable
								:disabled="formDisabled || model.tipousuariofijo"
								v-model="model"
								required
								modelName="vluptipousuario"
								:itemsToSelect="getUsertypes"
								:modelFormMapping="{ codtipusuario: 'idtipousuario' }"
								itemValue="codtipusuario"
								itemText="descripcion"
								valueColumnStyles="xs12 md4 lg4"
								:fixedFilter="tipousuarioFixedFilter"
								:key="tipousuarioKey"
								:order="{ descripcion: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<!--<v-flex xs12 md6 lg5> -->
						<v-flex xs12 md6 lg6>
							<pui-text-field
								:label="$t('usuarioaplicacion.usr')"
								v-model="model.usr"
								maxlength="100"
								required
								:rules="usrRules"
								:disabled="formDisabled || this.method != 'create'"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6 lg6>
							<pui-text-field
								:label="$t('usuarioaplicacion.name')"
								v-model="model.name"
								maxlength="200"
								required
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap v-if="this.method != 'create'">
						<v-flex xs6>
							<pui-checkbox
								:label="$t('usuarioaplicacion.disabled')"
								v-model="model.disabled"
								:trueValue="0"
								:falseValue="1"
								disabled
							></pui-checkbox>
						</v-flex>
						<v-flex xs6 v-if="model.disabled === 1">
							<pui-date-field
								:label="$t('usuarioaplicacion.disabled_date')"
								v-model="model.disableddate"
								disabled
								valueColumnStyles="xs4"
								attach="disableddate"
							></pui-date-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md6 lg6>
							<pui-text-field
								:label="$t('usuarioaplicacion.email')"
								v-model="model.email"
								maxlength="100"
								:required="model.idtipousuario !== 4"
								:disabled="formDisabled"
								:rules="emailRules"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6 lg6>
							<pui-select
								:label="$t('usuarioaplicacion.language')"
								v-model="model"
								clearable
								required
								:disabled="formDisabled"
								modelName="vpuilanguage"
								:itemsToSelect="[{ isocode: model.language }]"
								:modelFormMapping="{ isocode: 'language' }"
								itemValue="isocode"
								itemText="name"
								valueColumnStyles="xs12 md4 lg4"
								:order="{ name: 'asc' }"
							></pui-select>
						</v-flex>
						<v-flex xs12 md6 lg6>
							<input type="text" id="fakeusername" name="fakeusernameremembered" style="opacity: 0; position: absolute;" />
							<pui-text-field
								v-if="!passHidden"
								:label="$t('usuarioaplicacion.password')"
								v-model="model.password"
								maxlength="100"
								required
								noeditable
								:append-icon="hidePass ? 'visibility_off' : 'visibility'"
								:type="hidePass ? 'text' : 'password'"
								:disabled="formDisabled"
								:rules="passwordRules"
								:autocomplete="'new-password'"
								@click:append="hidePass = !hidePass"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6 lg6>
							<input type="text" id="fakeusername2" name="fakeusernameremembered2" style="opacity: 0; position: absolute;" />
							<pui-text-field
								v-if="!passHidden"
								:label="$t('usuarioaplicacion.repeatpassword')"
								v-model="model.repeatpassword"
								maxlength="100"
								noeditable
								:append-icon="hidePass ? 'visibility_off' : 'visibility'"
								:type="hidePass ? 'text' : 'password'"
								required
								:disabled="formDisabled"
								:rules="repeatPasswordRules"
								:autocomplete="'new-password'"
								@click:append="hidePass = !hidePass"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout>
						<v-flex xs12 md6 mx-2>
							<v-flex xs12>
								<pui-select
									v-if="modelLoaded && model.idtipousuario === 1"
									attach="idterminal"
									:label="$t('usuarioaplicacion.idterminal')"
									clearable
									:disabled="formDisabled || model.terminalfijo"
									v-model="model"
									required
									modelName="vlupterminales"
									:itemsToSelect="[{ idterminal: model.idterminal }]"
									:modelFormMapping="{ idterminal: 'idterminal' }"
									:itemValue="['idterminal']"
									:itemText="(item) => `${item.nombre}`"
									:order="{ nombre: 'asc' }"
								></pui-select>
							</v-flex>
							<v-flex xs12>
								<pui-select
									v-if="modelLoaded && model.idtipousuario === 2"
									attach="idcomprador"
									:label="$t('usuarioaplicacion.idcomprador')"
									clearable
									:disabled="formDisabled || model.compradorfijo"
									v-model="model"
									required
									modelName="vlupcompradores"
									:itemsToSelect="[{ idcomprador: model.idcomprador }]"
									:modelFormMapping="{ idcomprador: 'idcomprador' }"
									:itemValue="['idcomprador']"
									:itemText="(item) => `${item.nombre}`"
									:order="{ nombre: 'asc' }"
									id="idcompradorlup"
								></pui-select>
							</v-flex>
							<v-flex xs12>
								<pui-select
									v-if="modelLoaded && (model.idtipousuario === 3 || model.idtipousuario === 4)"
									attach="idtransportista"
									:label="$t('usuarioaplicacion.idtransportista')"
									clearable
									:disabled="formDisabled || model.transportistafijo"
									v-model="model"
									required
									modelName="vluptransportistas"
									:itemsToSelect="[{ idtransportista: model.idtransportista }]"
									:modelFormMapping="{ idtransportista: 'idtransportista' }"
									:itemValue="['idtransportista']"
									:itemText="(item) => `${item.nombre}`"
									:order="{ nombre: 'asc' }"
									id="idtransportistalup"
								></pui-select>
							</v-flex>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md3>
							<pui-text-field
								v-if="modelLoaded && model.idtipousuario === 4"
								:label="$t('usuarioaplicacion.nifconductor')"
								v-model="model.nifconductor"
								maxlength="20"
								required
								:disabled="formDisabled"
								labelColumnStyles="xs5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								v-if="modelLoaded && model.idtipousuario === 4"
								:label="$t('usuarioaplicacion.movilconductor')"
								v-model="model.movilconductor"
								maxlength="20"
								:disabled="formDisabled"
								:rules="movilRules"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								v-if="modelLoaded && model.idtipousuario === 4"
								:label="$t('usuarioaplicacion.matriconductor')"
								v-model="model.matriconductor2"
								maxlength="20"
								:disabled="formDisabled"
								:rules="matriculaRules"
								@blur="model.remolqueconductor = model.matriconductor2"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								v-if="modelLoaded && model.idtipousuario === 4"
								:label="$t('usuarioaplicacion.remolqueconductor')"
								v-model="model.remolqueconductor"
								maxlength="20"
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-flex>
		</v-layout>
		<div class="profilesform pui-form" :style="{ height: getPanelHeight }">
			<v-layout>
				<v-flex xs12>
					<pui-field-set :title="getTitleFieldSet2">
						<pui-multi-select
							attach="profiles"
							itemValue="profile"
							itemText="nameprofile"
							:itemsToSelect="model.profiles"
							:items="getProfiles"
							v-model="model.profiles"
							:disabled="formDisabled || tipoDisabled"
							:key="profilesKey"
							required
							id="profilesFields"
						></pui-multi-select>
					</pui-field-set>
				</v-flex>
			</v-layout>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			passHidden: false,
			hidePass: false,
			passwordRules: [(v) => this.validatePasswordRules(v) || this.$t('usuarioaplicacion.validate.pass')],
			emailRules: [(v) => this.validateEmailRules(v) || this.$t('usuarioaplicacion.validate.email')],
			repeatPasswordRules: [(v) => this.validateRepeatPasswordRules(v) || this.$t('usuarioaplicacion.validate.repeatPass')],
			movilRules: [(v) => this.validateMovilRules(v) || this.$t('usuarioaplicacion.validate.movil')],
			profilesKey: 0,
			allProfiles: [],
			profilesByTipousuario: [],
			profilesByTipousuarioSelected: [],
			tipousuarioSelected: null,
			tipousuarioKey: 0,
			tipoDisabled: false,
			matriculaRules: [(v) => this.validateMatriculaRules(v) || this.$t('usuarioaplicacion.validate.matricula')],
			usrRules: [(v) => this.validateUsernameRules(v) || this.$t('usuarioaplicacion.validate.usr')]
		};
	},
	created() {
		this.getAllProfiles();
	},
	watch: {
		'model.idtipousuario'(newValue) {
			if (newValue === 1) {
				if (!this.model.compradorfijo) {
					this.model.idcomprador = null;
				}
				this.model.idtransportista = null;
				this.model.nifconductor = null;
				this.model.matriconductor2 = null;
				this.model.remolqueconductor = null;
				this.model.movilconductor = null;
			} else if (newValue === 2) {
				if (!this.model.terminalfijo) {
					this.model.idterminal = null;
				}
				this.model.idtransportista = null;
				this.model.nifconductor = null;
				this.model.matriconductor2 = null;
				this.model.remolqueconductor = null;
				this.model.movilconductor = null;
			} else if (newValue === 3) {
				if (!this.model.terminalfijo) {
					this.model.idterminal = null;
				}
				if (!this.model.compradorfijo) {
					this.model.idcomprador = null;
				}
				this.model.nifconductor = null;
				this.model.matriconductor2 = null;
				this.model.remolqueconductor = null;
				this.model.movilconductor = null;
			} else if (newValue === 4) {
				if (!this.model.terminalfijo) {
					this.model.idterminal = null;
				}
				if (!this.model.compradorfijo) {
					this.model.idcomprador = null;
				}
			} else {
				if (!this.model.compradorfijo) {
					this.model.idcomprador = null;
				}
				if (!this.model.terminalfijo) {
					this.model.idterminal = null;
				}
				if (!this.model.transportistafijo) {
					this.model.idtransportista = null;
				}
				this.model.nifconductor = null;
				this.model.matriconductor2 = null;
				this.model.remolqueconductor = null;
				this.model.movilconductor = null;
			}
			this.tipousuarioSelected = newValue;
			this.tipoDisabled = false;
			if (newValue === 3 && this.model.compradorfijo) {
				this.tipoDisabled = true;
			} else if (newValue === 2 && this.model.terminalfijo) {
				this.tipoDisabled = true;
			}
			this.model.profiles = this.profilesByTipousuarioSelected[this.tipousuarioSelected];
			this.profilesKey += 1;

			if (this.tipoDisabled || this.formDisabled) {
				this.deshabilitaCampo('profilesFields');
			} else {
				this.habilitaCampo('profilesFields');
			}
		}
	},
	computed: {
		getTitleFieldSet1() {
			return this.$t('usuarioaplicacion.title');
		},
		getTitleFieldSet2() {
			return this.$t('usuarioaplicacion.profiles');
		},
		getProfiles() {
			return !this.tipousuarioSelected ? this.allProfiles : this.profilesByTipousuario[this.model.idtipousuario];
		},
		getPanelHeight() {
			return 400 + 'px';
		},
		getUsertypes() {
			return this.method === 'create' && this.model.idtipousuario === 3
				? [{ codtipusuario: 4 }]
				: [{ codtipusuario: this.model.idtipousuario }];
		}
	},
	mounted() {
		if (this.method === 'update' || this.method === 'read') {
			this.passHidden = true;
		}
		this.watcherLupTrans();
		this.watcherLupComp();
	},
	methods: {
		afterSave(userModel) {
			if (userModel.usr === this.$store.getters.getUser && userModel.language !== this.$store.getters.getUserLanguage) {
				this.changeAppLang(userModel.language);
			}
		},
		changeAppLang(lang) {
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
			this.$puiEvents.$emit('onPuiChangedLang', lang);
		},
		validatePasswordRules(v) {
			var re = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,})|((?=.*\W)(?=.*[a-zA-Z])(?=.*\d).{8,})|((?=.*\W)(?=.*[a-z])(?=.*[A-Z]).{8,})$/i;
			return re.test(v);
		},
		validateEmailRules(v) {
			// eslint-disable-next-line
			var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
			if (v === null || v === '' || v === undefined) {
				return true;
			}
			return re.test(v);
		},
		validateRepeatPasswordRules(v) {
			return v === this.model.password;
		},
		validateMovilRules(v) {
			var re = /^[0-9]*$/i;
			if (v === null || v === '' || v === undefined) {
				return true;
			}
			return re.test(v);
		},
		getAllProfiles() {
			this.$puiRequests.postRequest('/puisearch', { model: 'vluptipousuarioperfil', rows: 1000 }, (response) => {
				if (response && response.data && response.data.data) {
					var allProfiles = response.data.data;
					this.profilesByTipousuario = [];
					this.profilesByTipousuarioSelected = [];

					for (var i = 0, length = allProfiles.length; i < length; i++) {
						var objetolocal = allProfiles[i];
						var idtipousuariolocal = objetolocal.idtipousuario;
						//var profilelocal = objetolocal.profile;
						if (idtipousuariolocal === 3 && this.model.compradorfijo) {
							//if (profilelocal === 'ADMIN_TRANSPORTISTA') {
							objetolocal.indseleccionado = '1';
							//} else {
							//	objetolocal.indseleccionado = '0';
							//}
						} else if (idtipousuariolocal === 2 && this.model.terminalfijo) {
							//if (profilelocal === 'ADMIN_COMPRADOR') {
							objetolocal.indseleccionado = '1';
							//} else {
							//	objetolocal.indseleccionado = '0';
							//}
						}
						var indseleccionadolocal = objetolocal.indseleccionado;

						if (!this.profilesByTipousuario[idtipousuariolocal]) {
							this.profilesByTipousuario[idtipousuariolocal] = [objetolocal];
							if (!this.profilesByTipousuarioSelected[idtipousuariolocal]) {
								this.profilesByTipousuarioSelected[idtipousuariolocal] = [];
							}
						} else if (this.profilesByTipousuario[idtipousuariolocal]) {
							this.profilesByTipousuario[idtipousuariolocal].push(objetolocal);
						}
						if (!this.profilesByTipousuarioSelected[idtipousuariolocal] && indseleccionadolocal === '1') {
							this.profilesByTipousuarioSelected[idtipousuariolocal] = [objetolocal];
						} else if (this.profilesByTipousuarioSelected[idtipousuariolocal] && indseleccionadolocal === '1') {
							this.profilesByTipousuarioSelected[idtipousuariolocal].push(objetolocal);
						}
					}
					this.tipousuarioSelected = this.model.idtipousuario;
					if (this.method === 'create') {
						this.model.profiles = this.profilesByTipousuarioSelected[this.tipousuarioSelected];
					}
					this.profilesKey += 1;
				}
			});
		},
		watcherLupTrans() {
			this.$puiEvents.$on('onPuiSelect_selectedItems-idtransportistalup', (newVal) => {
				var lupamodel = newVal.model;
				if (lupamodel !== undefined) {
					this.model.empresa = lupamodel.nombre;
				}
			});
		},
		watcherLupComp() {
			this.$puiEvents.$on('onPuiSelect_selectedItems-idcompradorlup', (newVal) => {
				var lupamodel = newVal.model;
				if (lupamodel !== undefined) {
					this.model.empresa = lupamodel.nombre;
				}
			});
		},
		insertAfter(referenceNode, newNode) {
			referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
		},
		doEnableDiv(dvName) {
			if (document.getElementById(dvName + 'Disabled')) {
				var elem = document.getElementById(dvName + 'Disabled');
				elem.parentNode.removeChild(elem);
			}
		},
		doDisableDiv(dvName) {
			var nDiv = document.createElement('div');
			nDiv.setAttribute('id', dvName + 'Disabled');
			nDiv.style.display = 'block';
			nDiv.style.position = 'relative';
			nDiv.style.width = document.getElementById(dvName).offsetWidth + 'px';
			nDiv.style.height = document.getElementById(dvName).offsetHeight + 'px';
			nDiv.style.top = '-' + nDiv.style.height;
			nDiv.style.left = '0px';
			nDiv.className = 'transparencia';
			var myBody = document.getElementById(dvName);
			this.insertAfter(myBody, nDiv);
		},
		habilitaCampo(id) {
			var campo = document.getElementById(id);
			if (campo != null) {
				this.doEnableDiv(campo.id);
			}
		},
		deshabilitaCampo(id) {
			var campo = document.getElementById(id);
			if (campo != null) {
				this.doDisableDiv(campo.id);
			}
		},
		validateMatriculaRules(v) {
			var re = /^[a-z0-9A-Z]*$/;
			return re.test(v);
		},
		validateUsernameRules(v) {
			if (this.method === 'create') {
				var re = /[^a-z0-9Ññ]/gi;
				if (v === null || v === '' || v === undefined) {
					return true;
				}
				return !re.test(v);
			} else {
				return true;
			}
		}
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		tipousuarioFixedFilter: {
			type: Object,
			required: false
		}
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-idtransportistalup');
		this.$puiEvents.$off('onPuiSelect_selectedItems-idcompradorlup');
	}
};
</script>
<style lang="postcss">
.profilesform {
	overflow-y: auto;
}

.transparencia {
	background-color: #eee;
	border: none;
	opacity: 0;
	filter: 'alpha(opacity=0)';
	filter: alpha(opacity=0);
	zoom: 1;
}
</style>
<i18n>
{
	"en": {
		"usuarioaplicacion.title": "User data",
		"usuarioaplicacion.usr": "User",
		"usuarioaplicacion.email": "Email",
		"usuarioaplicacion.name": "Name",
		"usuarioaplicacion.language": "Language",
		"usuarioaplicacion.password": "Password",
		"usuarioaplicacion.repeatpassword": "Repeat Password",
		"usuarioaplicacion.disabled": "Disabled",
		"usuarioaplicacion.profiles": "User profiles",
		"usuarioaplicacion.changeImage":"Select ...",
		"usuarioaplicacion.idtipousuario":"User type",
		"usuarioaplicacion.idterminal":"Terminal",
		"usuarioaplicacion.idcomprador":"Buyer",
		"usuarioaplicacion.idtransportista":"Transportista",
		"usuarioaplicacion.disabled_date": "Disabled date",
		"usuarioaplicacion.nifconductor": "Tax Identifier",
		"usuarioaplicacion.matriconductor": "License plate",
		"usuarioaplicacion.remolqueconductor": "Trailer",
		"usuarioaplicacion.movilconductor": "Mobile",

		"usuarioaplicacion.validate.repeatPass":"Passwords must match",
		"usuarioaplicacion.validate.email":"The email is not well-formed 'email@domain.com'",
		"usuarioaplicacion.validate.movil":"The mobile must contain numbers"
	},
	"es": {
		"usuarioaplicacion.title": "Información de usuario",
		"usuarioaplicacion.usr": "Usuario",
		"usuarioaplicacion.email": "Email",
		"usuarioaplicacion.name": "Nombre",
		"usuarioaplicacion.language": "Idioma",
		"usuarioaplicacion.password": "Contraseña",
		"usuarioaplicacion.repeatpassword": "Repetir contraseña",
		"usuarioaplicacion.disabled": "Activo",
		"usuarioaplicacion.profiles": "Perfiles del usuario",
		"usuarioaplicacion.changeImage":"Seleccionar ...",
		"usuarioaplicacion.change":"Seleccionar ...",
		"usuarioaplicacion.idtipousuario":"Tipo usuario",
		"usuarioaplicacion.idterminal":"Terminal",
		"usuarioaplicacion.idcomprador":"Comprador",
		"usuarioaplicacion.idtransportista":"Transportista",
		"usuarioaplicacion.disabled_date": "Fecha desactivación",
		"usuarioaplicacion.nifconductor": "Identificador fiscal",
		"usuarioaplicacion.matriconductor": "Matrícula",
		"usuarioaplicacion.remolqueconductor": "Remolque",
		"usuarioaplicacion.movilconductor": "Móvil",

		"usuarioaplicacion.validate.repeatPass":"Las Contraseñas tienen que coincidir",
		"usuarioaplicacion.validate.email":"El email no está bien formado 'email@dominio.com'",
		"usuarioaplicacion.validate.movil":"El móvil debe ser numérico"
	},
	"ca": {
		"usuarioaplicacion.title": "Informació d'usuari",
		"usuarioaplicacion.usr": "Usuari",
		"usuarioaplicacion.email": "Email",
		"usuarioaplicacion.name": "Nom",
		"usuarioaplicacion.language": "Idioma",
		"usuarioaplicacion.password": "Contraseña",
		"usuarioaplicacion.repeatpassword": "Repetir Password",
		"usuarioaplicacion.disabled": "Actiu",
		"usuarioaplicacion.profiles": "Perfils d'usuari",
		"usuarioaplicacion.changeImage":"Seleccionar ...",
		"usuarioaplicacion.idtipousuario":"Tipus usuari",
		"usuarioaplicacion.idterminal":"Terminal",
		"usuarioaplicacion.idcomprador":"Comprador",
		"usuarioaplicacion.idtransportista":"Transportista",
		"usuarioaplicacion.disabled_date": "Data baixa",
		"usuarioaplicacion.nifconductor": "Identificador fiscal conductor",
		"usuarioaplicacion.matriconductor": "Matrícula",
		"usuarioaplicacion.remolqueconductor": "Remolc",
		"usuarioaplicacion.movilconductor": "Mobil",

		"usuarioaplicacion.validate.repeatPass":"Les Contrasenyes han de coincidir",
		"usuarioaplicacion.validate.email":"L'email no està ben format 'email@domini.com'",
		"usuarioaplicacion.validate.movil":"El mobil debe ser numéric"
	}
}
</i18n>
