const disableCamionAction = {
	id: 'disableCamionAction',
	selectionType: 'single',
	label: 'camion.disablecamion',
	functionality: 'DISABLE_CAMION',
	checkAvailability: function (camion) {
		if (camion[0] && camion[0].indbaja === '0') {
			return true;
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		// Comprobar PK (En este caso es compuesta)
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.fecbaja = this.$dateTimeUtils.getLocalDateNow();
		this.$puiEvents.$emit('pui-modalDialogForm-disable-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [disableCamionAction],
	formactions: [disableCamionAction]
};
