<template>
	<div class="pui-form">
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<asignacioncompformheader></asignacioncompformheader>
		</pui-form-header>
		<pui-form-header :showHeader="isCreatingElement" v-if="modelLoaded && encargoLoaded">
			<encargocompformheader :modelPk="encargoPk" origen="CM"></encargocompformheader>
		</pui-form-header>
		<v-form class="pui-autorizacionform mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout v-if="dinamycForm.fromGrid && method !== 'create'" class="pui-form-layout ml-2">
				<v-flex xs12>
					<fieldset>
						<v-layout class="mt-2">
							<v-flex xs12>
								<v-layout wrap class="pui-form-layout">
									<v-flex xs12>
										<pui-number-field
											:label="getDeslote"
											v-model="model.lotes[0].pesolote"
											decimals="0"
											max="8000000"
											disabled
											id="pesolote"
											suffix="Kg"
											labelColumnStyles="xs12 sm1"
											valueColumnStyles="xs12 sm2"
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout wrap class="pui-form-layout">
									<v-flex xs12>
										<asignacion-form
											:model="model"
											:formDisabled="formDisabled"
											:modelLoaded="modelLoaded"
											:method="method"
										></asignacion-form>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</fieldset>
				</v-flex>
			</v-layout>
			<v-layout v-else>
				<v-flex xs12>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12 v-for="(lote, index) in model.lotes" :key="index">
							<asignacion-lote-form
								:model="lote"
								:fromGrid="dinamycForm.fromGrid"
								:formDisabled="formDisabled"
								:modelLoaded="modelLoaded"
								:indice="index + 1"
								:method="method"
							></asignacion-lote-form>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="savevalidate()">
										{{ this.$t('form.save') }}
									</v-btn>

									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
		<pui-modal-dialog
			v-if="modals.minorpeso.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.minorpeso.disableOk"
			:okText="$t('form.accept')"
			:messageText="$t('asignacion.modal.minorpeso.texto')"
			:titleText="modals.minorpeso.titleText"
			:dialogName="`minorpeso-${this.modelName}`"
			ref="minorpeso"
		></pui-modal-dialog>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import AsignacionLoteForm from './AsignacionLoteForm';
import AsignacionForm from './AsignacionForm';

export default {
	name: 'asignacion',
	mixins: [PuiFormMethodsMixin],
	components: { AsignacionLoteForm, AsignacionForm },
	data() {
		return {
			modelName: 'asignacion',
			dinamycForm: { fromGrid: false },
			modals: {
				minorpeso: {
					disableOk: false,
					show: false,
					titleText: this.$t('asignacion.modal.minorpeso.title')
				}
			},
			semaforo: false,
			encargoPk: null,
			encargoLoaded: false
		};
	},
	computed: {
		getDeslote() {
			return this.$t('asignacion.pesolote');
		}
	},
	methods: {
		// override
		afterGetData() {
			if (this.method === 'create') {
				const bypassModel = this.$store.getters.getModelByName(this.modelName);
				if (bypassModel && bypassModel.altaObjectPk) {
					// get copy model data and assign to current model
					this.getDataFromAltaObject(bypassModel);
					bypassModel.altaObjectPk = null;
				}

				//				this.subscribeToModalDialogEvent();
			}
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel.fromGrid) {
				this.dinamycForm.fromGrid = thisModel.fromGrid;
				delete thisModel.fromGrid;
			}
			this.subscribeToModalDialogEvent();
		},
		getDataFromAltaObject(bypassModel) {
			const controller = bypassModel.url.datosalta + this.getParams(bypassModel.altaObjectPk);

			this.$puiRequests.getRequest(
				controller,
				null,
				(response) => {
					if (response && response.data) {
						var data = response.data;
						this.model = Object.assign({}, data);
						if (!this.dinamycForm.fromGrid) {
							this.model.lotes.forEach((lote) => {
								lote.asignaciones.forEach((asignacion) => {
									asignacion.pesoasignado = null;
								});
							});
						}
						console.log(this.model);
						this.encargoPk = { idencargo: this.model.idencargo };
						this.encargoLoaded = true;
					}
				},

				(error) => {
					this.onError(error);
				}
			);
		},
		savevalidate() {
			if (this.semaforo) {
				this.$puiNotify.error(this.$t('error.operacion_en_curso'));
				return;
			}
			this.semaforo = true;

			if (this.dinamycForm.fromGrid) {
				var lote0 = this.model.lotes[0];
				var suma0 = 0;
				for (var idxasig0 in lote0.asignaciones) {
					var asig0 = lote0.asignaciones[idxasig0];
					if (asig0.idasignacion === this.model.idasignacion) {
						suma0 = suma0 + Number(this.model.pesoasignado);
					} else {
						suma0 = suma0 + Number(asig0.pesoasignado);
					}
				}

				if (suma0 < lote0.pesolote) {
					minor = true;
				}
				//				this.save(false);
				//				this.semaforo = false;
			} else {
				var minor = false;
				for (var idxlote in this.model.lotes) {
					var lote = this.model.lotes[idxlote];
					var suma = 0;
					for (var idxasig in lote.asignaciones) {
						var asig = lote.asignaciones[idxasig];
						suma = suma + Number(asig.pesoasignado);
					}

					if (suma < lote.pesolote) {
						minor = true;
						break;
					}
				}
			}

			if (minor) {
				this.$puiEvents.$emit('pui-modalDialog-minorpeso-' + this.modelName + '-show', '');
				this.$puiEvents.$on('pui-action-runAction-' + this.modelName, () => {
					this.$puiEvents.$off('pui-action-runAction-' + this.modelName);
					console.log(this.encargoPk);
					this.save(false);
					this.semaforo = false;
				});
			} else {
				this.save(false);
				this.semaforo = false;
			}
			//			}
		},
		beforeSave() {
			if (this.dinamycForm.fromGrid && this.method !== 'create') {
				this.model.multiple = false;
			}
		},
		mounted() {
			//this.subscribeToModalDialogEvent();
		},
		subscribeToModalDialogEvent() {
			// Modal Minorpeso
			this.$puiEvents.$on(`pui-modalDialog-minorpeso-${this.modelName}-ok`, () => {
				this.setShowMinorpeso(false);
				this.$puiEvents.$emit('pui-action-runAction-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-minorpeso-${this.modelName}-cancel`, () => {
				this.semaforo = false;
				this.setShowMinorpeso(false);
				//this.$puiEvents.$off('pui-action-runAction-' + this.modelName);
				//this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-minorpeso-${this.modelName}-show`, () => {
				this.setShowMinorpeso(true);
			});
		},
		setShowMinorpeso(action) {
			this.modals.minorpeso.show = action;
		},
		getFormHeaderTitle() {
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel.fromGrid) {
				return this.$t('form.asignacion');
			} else {
				return this.$t('encargo.crearAsignacion');
			}
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-minorpeso-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-minorpeso-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-minorpeso-${this.modelName}-show`);
	}
};
</script>
<style scoped>
.lotelabel {
	font-weight: bold;
}
</style>

<i18n>
{
    "en": {
		"asignacion.pesolote": "Lot",
		"asignacion.modal.minorpeso.title": "Assigned kg",
		"asignacion.modal.minorpeso.texto": "There are lots that are not completely assigned. The unallocated weight will be lost. Do you want to continue?"
    },
    "es": {
		"asignacion.pesolote": "Lote",
		"asignacion.modal.minorpeso.title": "Kilos asignados",
		"asignacion.modal.minorpeso.texto": "Hay lotes que no están completamente asignados. El peso no asignado se perderá. ¿Desea continuar?"
    },
    "ca": {
		"asignacion.pesolote": "Lot",
		"asignacion.modal.minorpeso.title": "Quilos assignats",
		"asignacion.modal.minorpeso.texto": "Hi ha lots que no estan totalment assignats. El pes no assignat es perdrà. Voleu continuar?"
	}
}
</i18n>
