<template>
	<pui-field-set>
		<v-layout wrap>
			<v-flex xs12 md4>
				<pui-number-field
					:label="getDeslote"
					v-model="model.pesolote"
					disabled
					id="pesolote"
					suffix="Kg"
					labelColumnStyles="xs12 md2"
					valueColumnStyles="xs12 md8"
				></pui-number-field>
			</v-flex>
		</v-layout>
		<div v-for="(asigtransp, index2) in model.asignaciones" :key="index2">
			<asignacion-lote-transp-form
				:model="asigtransp"
				:formDisabled="formDisabled"
				:modelLoaded="modelLoaded"
				:parentlist="model.asignaciones"
				:fromGrid="fromGrid"
				:indice="index2"
				:method="method"
			></asignacion-lote-transp-form>
		</div>
		<v-layout wrap>
			<v-flex xs12>
				<v-btn depressed color="secondary" class="elevation-0" @click.native="addTransportista()">{{ this.$t('addTransportista') }}</v-btn>
			</v-flex>
		</v-layout>
	</pui-field-set>
</template>

<script>
import AsignacionLoteTranspForm from './AsignacionLoteTranspForm';

export default {
	data() {
		return {};
	},
	computed: {
		getDeslote() {
			//return this.model && this.model.descripcion ? this.model.descripcion : '';
			var texto = '';
			if (this.method === 'create') {
				texto = ' ' + this.indice;
			}
			return this.$t('asignacion.pesolote') + texto;
		}
	},
	watch: {},
	methods: {
		// override
		afterGetData() {},
		addTransportista() {
			this.model.asignaciones.push({});
		}
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		indice: {
			type: Number,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		fromGrid: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: { AsignacionLoteTranspForm }
};
</script>
<style scoped>
.lotelabel label {
	font-weight: bold !important;
}
</style>

<i18n>
{
    "en": {
		"asignacion.idlote": "Lot code",
		"asignacion.pesolote": "Lot",
		"asignacion.idtransportista": "Carrier",
		"asignacion.addTransportista.link.text": "Add Carrier",
		"asignacion.kilosasig": "Assigned kg",
        "addTransportista": "+"
    },
    "es": {
		"asignacion.idlote": "Código lote",
        "asignacion.pesolote": "Lote",
        "asignacion.idtransportista": "Transportista",
		"asignacion.addTransportista.link.text": "Añadir transportista",
		"asignacion.kilosasig": "Kilos asignados",
        "addTransportista": "+"
    },
    "ca": {
		"asignacion.idlote": "Codi lot",
        "asignacion.pesolote": "Lot",
        "asignacion.idtransportista": "Transportista",
		"asignacion.addTransportista.link.text": "Afegir transportista",
		"asignacion.kilosasig": "Quilos assignats",
        "addTransportista": "+"
    }
}
</i18n>
