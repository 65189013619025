import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// your own components imports

import CompradorForm from '@/components/comprador/CompradorForm.vue';
import CompradorGrid from '@/components/comprador/CompradorGrid.vue';
import CompradorFormHeader from '@/components/comprador/CompradorFormHeader.vue';

import TerminalForm from '@/components/terminal/TerminalForm.vue';
import TerminalGrid from '@/components/terminal/TerminalGrid.vue';
import TerminalFormHeader from '@/components/terminal/TerminalFormHeader.vue';

import TransportistaForm from '@/components/transportista/TransportistaForm.vue';
import TransportistaGrid from '@/components/transportista/TransportistaGrid.vue';
import TransportistaFormHeader from '@/components/transportista/TransportistaFormHeader.vue';

import MercanciaForm from '@/components/mercancia/MercanciaForm.vue';
import MercanciaGrid from '@/components/mercancia/MercanciaGrid.vue';

import UsuarioAplicacionForm from '@/components/usuarioaplicacion/UsuarioAplicacionForm.vue';
import UsuarioAplicacionFormHeader from '@/components/usuarioaplicacion/UsuarioAplicacionFormHeader.vue';
import UsuarioCompradorFormHeader from '@/components/usuarioaplicacion/UsuarioCompradorFormHeader.vue';
import UsuarioTerminalFormHeader from '@/components/usuarioaplicacion/UsuarioTerminalFormHeader.vue';
import UsuarioTransportistaFormHeader from '@/components/usuarioaplicacion/UsuarioTransportistaFormHeader.vue';
import UsuarioAplicacionGrid from '@/components/usuarioaplicacion/UsuarioAplicacionGrid.vue';
import UsuarioAplicacionSettingsForm from '@/components/usuarioaplicacion/UsuarioAplicacionSettingsForm.vue';

import PuntoCargaForm from '@/components/puntocarga/PuntoCargaForm.vue';
import PuntoCargaGrid from '@/components/puntocarga/PuntoCargaGrid.vue';
import PuntoCargaFormHeader from '@/components/puntocarga/PuntoCargaFormHeader.vue';

import PuntoCargaAptForm from '@/components/puntocarga/apt/PuntoCargaAptForm.vue';
import PuntoCargaAptGrid from '@/components/puntocarga/apt/PuntoCargaAptGrid.vue';

import MotivoAnulRechForm from '@/components/motivoanulrech/MotivoAnulRechForm.vue';
import MotivoAnulRechGrid from '@/components/motivoanulrech/MotivoAnulRechGrid.vue';
import MotivoAnulRechFormHeader from '@/components/motivoanulrech/MotivoAnulRechFormHeader.vue';

import AutorizacionForm from '@/components/autorizacion/AutorizacionForm.vue';
import AutorizacionGrid from '@/components/autorizacion/AutorizacionGrid.vue';

import EncargoCompForm from '@/components/encargo/comprador/EncargoCompForm.vue';
import EncargoCompGrid from '@/components/encargo/comprador/EncargoCompGrid.vue';
import EncargoCompFormHeader from '@/components/encargo/comprador/EncargoCompFormHeader.vue';

import EncargoTermForm from '@/components/encargo/terminal/EncargoTermForm.vue';
import EncargoTermGrid from '@/components/encargo/terminal/EncargoTermGrid.vue';
import EncargoTermFormHeader from '@/components/encargo/terminal/EncargoTermFormHeader.vue';

import ConsultarealizadaForm from '@/components/consultarealizada/ConsultarealizadaForm.vue';
import ConsultarealizadaGrid from '@/components/consultarealizada/ConsultarealizadaGrid.vue';
import ConsultaRealizadaFormHeader from '@/components/consultarealizada/ConsultarealizadaFormHeader.vue';

import ConsultarecibidaForm from '@/components/consultarecibida/ConsultarecibidaForm.vue';
import ConsultarecibidaGrid from '@/components/consultarecibida/ConsultarecibidaGrid.vue';
import ConsultaRecibidaFormHeader from '@/components/consultarecibida/ConsultarecibidaFormHeader.vue';

import TimeslotPlanifForm from '@/components/timeslotplanif/TimeslotPlanifForm.vue';
import TimeslotPlanifGrid from '@/components/timeslotplanif/TimeslotPlanifGrid.vue';
import TimeslotPlanifFormHeader from '@/components/timeslotplanif/TimeslotPlanifFormHeader.vue';

import TimeslotGrid from '@/components/timeslot/TimeslotGrid.vue';
import TimeslotForm from '@/components/timeslot/TimeslotForm.vue';

import TimeslotPcGrid from '@/components/timeslot/pc/TimeslotPcGrid.vue';
import TimeslotPcForm from '@/components/timeslot/pc/TimeslotPcForm.vue';

import LotegeneradoForm from '@/components/lotegenerado/LotegeneradoForm.vue';
import LotegeneradoGrid from '@/components/lotegenerado/LotegeneradoGrid.vue';

import AppContainerHeader from '@/components/appcontainerheader/AppContainerHeader.vue';

import AsignacionEncargoForm from '@/components/asignacion/AsignacionEncargoForm.vue';
import AsignacionGrid from '@/components/asignacion/AsignacionGrid.vue';

import AsignacionTransForm from '@/components/asignacion/AsignacionTransForm.vue';
import AsignacionTransGrid from '@/components/asignacion/AsignacionTransGrid.vue';

import UsuarioTransportistaForm from '@/components/usuarioaplicacion/UsuarioTransportistaForm.vue';
import UsuarioTransportistaGrid from '@/components/usuarioaplicacion/UsuarioTransportistaGrid.vue';

import UsuarioTerminalForm from '@/components/usuarioaplicacion/UsuarioTerminalForm.vue';
import UsuarioTerminalGrid from '@/components/usuarioaplicacion/UsuarioTerminalGrid.vue';

import UsuarioCompradorForm from '@/components/usuarioaplicacion/UsuarioCompradorForm.vue';
import UsuarioCompradorGrid from '@/components/usuarioaplicacion/UsuarioCompradorGrid.vue';

import TipoUsuarioPerfilGrid from '@/components/tipousuarioperfil/TipoUsuarioPerfilGrid.vue';
import TipoUsuarioPerfilForm from '@/components/tipousuarioperfil/TipoUsuarioPerfilForm.vue';

import ReAsignacionTransForm from '@/components/asignacion/ReAsignacionTransForm.vue';
import GenerarLibramientoTransForm from '@/components/asignacion/GenerarLibramientoTransForm.vue';

import LoterecibidoGrid from '@/components/loterecibido/LoterecibidoGrid.vue';
import LoterecibidoForm from '@/components/loterecibido/LoterecibidoForm.vue';

import LibramientoCompGrid from '@/components/libramiento/comprador/LibramientoCompGrid.vue';
import LibramientoCompForm from '@/components/libramiento/comprador/LibramientoCompForm.vue';

import LibramientoTermgGrid from '@/components/libramiento/terminalg/LibramientoTermgGrid.vue';
import LibramientoTermgForm from '@/components/libramiento/terminalg/LibramientoTermgForm.vue';

import LibramientoTermcGrid from '@/components/libramiento/terminalc/LibramientoTermcGrid.vue';
import LibramientoTermcForm from '@/components/libramiento/terminalc/LibramientoTermcForm.vue';

import LibramientoTransGrid from '@/components/libramiento/transportista/LibramientoTransGrid.vue';
import LibramientoTransForm from '@/components/libramiento/transportista/LibramientoTransForm.vue';

import LibramientoFormHeader from '@/components/libramiento/LibramientoFormHeader.vue';
import LibramientoTermgFormHeader from '@/components/libramiento/terminalg/LibramientoTermgFormHeader.vue';
import LibramientoTermcFormHeader from '@/components/libramiento/terminalc/LibramientoTermcFormHeader.vue';
import LibramientoTransFormHeader from '@/components/libramiento/transportista/LibramientoTransFormHeader.vue';

import HistoricoCompGrid from '@/components/historicocomp/HistoricoCompGrid.vue';
import HistoricoTermGrid from '@/components/historicoterm/HistoricoTermGrid.vue';
import HistoricoLibTransTermcGrid from '@/components/historicolibtranstermc/HistoricoLibTransTermcGrid.vue';
import HistoricoLibTransGrid from '@/components/historicolibtrans/HistoricoLibTransGrid.vue';
import HistoricoLibTermcGrid from '@/components/historicolibtermc/HistoricoLibTermcGrid.vue';
import HistoricoAccionTerminalGrid from '@/components/historicoaccionterminal/HistoricoAccionTerminalGrid.vue';

import AsignacionTransFormHeader from '@/components/asignacion/AsignacionTransFormHeader.vue';
import AsignacionCompFormHeader from '@/components/asignacion/AsignacionCompFormHeader.vue';
import GenerarLibramientoTransFormHeader from '@/components/asignacion/GenerarLibramientoTransFormHeader.vue';

import DocumentacionForm from '@/components/documentacion/DocumentacionForm.vue';
import DocumentacionGrid from '@/components/documentacion/DocumentacionGrid.vue';
import DocumentacionFormHeader from '@/components/documentacion/DocumentacionFormHeader.vue';
import DocumentacioncabForm from '@/components/documentacion/DocumentacionCabForm.vue';
import DocumentacioncabGrid from '@/components/documentacion/DocumentacionCabGrid.vue';
import TipoDocumentoForm from '@/components/tipodocumento/TipoDocumentoForm.vue';
import TipoDocumentoGrid from '@/components/tipodocumento/TipoDocumentoGrid.vue';

import NovedadesForm from '@/components/novedades/NovedadesForm.vue';
import NovedadesGrid from '@/components/novedades/NovedadesGrid.vue';
import NovedadesFormHeader from '@/components/novedades/NovedadesFormHeader.vue';

import CamionForm from '@/components/camion/CamionForm.vue';
import CamionGrid from '@/components/camion/CamionGrid.vue';
import CamionFormHeader from '@/components/camion/CamionFormHeader.vue';

import ConductorbloqueadoForm from '@/components/conductorbloqueado/ConductorbloqueadoForm.vue';
import ConductorbloqueadoGrid from '@/components/conductorbloqueado/ConductorbloqueadoGrid.vue';

/* START # DETAILS FOR THE SELECT INFO BUTTONS # */
import DetailEncargoAuditForm from '@/components/selectdetails/DetailEncargoAuditForm.vue';
import DetailConsultaForm from '@/components/selectdetails/DetailConsultaForm.vue';
import DetailLoteForm from '@/components/selectdetails/DetailLoteForm.vue';
import DetailEncargoTermForm from '@/components/selectdetails/DetailEncargoTermForm.vue';
import DetailEncargoCompForm from '@/components/selectdetails/DetailEncargoCompForm.vue';
/* END # DETAILS FOR THE SELECT INFO BUTTONS # */

/* START # PANTALLAS RELACIONADAS CON AUDITORIA # */
import ConsultaAuditGrid from '@/components/auditorias/consulta/ConsultaAuditGrid.vue';
import ConsultaAuditForm from '@/components/auditorias/consulta/ConsultaAuditForm.vue';
import LoteAuditGrid from '@/components/auditorias/lote/LoteAuditGrid.vue';
import LoteAuditForm from '@/components/auditorias/lote/LoteAuditForm.vue';
import LibramientoAuditGrid from '@/components/auditorias/libramiento/LibramientoAuditGrid.vue';
import LibramientoAuditForm from '@/components/auditorias/libramiento/LibramientoAuditForm.vue';
import EncargoAuditGrid from '@/components/auditorias/encargo/EncargoAuditGrid.vue';
import EncargoAuditForm from '@/components/auditorias/encargo/EncargoAuditForm.vue';
import AsignacionAuditGrid from '@/components/auditorias/asignacion/AsignacionAuditGrid.vue';
import AsignacionAuditForm from '@/components/auditorias/asignacion/AsignacionAuditForm.vue';
import HistoricoAuditGrid from '@/components/auditorias/historico/HistoricoAuditGrid.vue';
import TimeslotAuditGrid from '@/components/auditorias/timeslot/TimeslotAuditGrid.vue';
import TimeslotAuditForm from '@/components/auditorias/timeslot/TimeslotAuditForm.vue';
import TimeslotPlanifAuditGrid from '@/components/auditorias/timeslotplanif/TimeslotPlanifAuditGrid.vue';
import TimeslotPlanifAuditForm from '@/components/auditorias/timeslotplanif/TimeslotPlanifAuditForm.vue';

import AuditAsignacionForm from '@/components/auditasignacion/AuditAsignacionForm.vue';
import AuditAsignacionGrid from '@/components/auditasignacion/AuditAsignacionGrid.vue';
/* END # PANTALLAS RELACIONADAS CON AUDITORIA # */

import AppLoginActions from '@/components/apploginactions/AppLoginActions.vue';

Vue.component(AppContainerHeader.name, AppContainerHeader);
/* START REGISTRY HEADERS */
Vue.component(ConsultaRecibidaFormHeader.name, ConsultaRecibidaFormHeader);
Vue.component(ConsultaRealizadaFormHeader.name, ConsultaRealizadaFormHeader);
Vue.component(EncargoCompFormHeader.name, EncargoCompFormHeader);
Vue.component(EncargoTermFormHeader.name, EncargoTermFormHeader);
Vue.component(LibramientoFormHeader.name, LibramientoFormHeader);
Vue.component(LibramientoTermgFormHeader.name, LibramientoTermgFormHeader);
Vue.component(LibramientoTermcFormHeader.name, LibramientoTermcFormHeader);
Vue.component(LibramientoTransFormHeader.name, LibramientoTransFormHeader);
Vue.component(AsignacionTransFormHeader.name, AsignacionTransFormHeader);
Vue.component(AsignacionCompFormHeader.name, AsignacionCompFormHeader);
Vue.component(GenerarLibramientoTransFormHeader.name, GenerarLibramientoTransFormHeader);
Vue.component(TimeslotPlanifFormHeader.name, TimeslotPlanifFormHeader);
Vue.component(UsuarioAplicacionFormHeader.name, UsuarioAplicacionFormHeader);
Vue.component(UsuarioCompradorFormHeader.name, UsuarioCompradorFormHeader);
Vue.component(UsuarioTerminalFormHeader.name, UsuarioTerminalFormHeader);
Vue.component(UsuarioTransportistaFormHeader.name, UsuarioTransportistaFormHeader);
Vue.component(AsignacionTransFormHeader.name, AsignacionTransFormHeader);
Vue.component(PuntoCargaFormHeader.name, PuntoCargaFormHeader);
Vue.component(DocumentacionFormHeader.name, DocumentacionFormHeader);
Vue.component(CompradorFormHeader.name, CompradorFormHeader);
Vue.component(TerminalFormHeader.name, TerminalFormHeader);
Vue.component(TransportistaFormHeader.name, TransportistaFormHeader);
Vue.component(CamionFormHeader.name, CamionFormHeader);
Vue.component(MotivoAnulRechFormHeader.name, MotivoAnulRechFormHeader);
Vue.component(NovedadesFormHeader.name, NovedadesFormHeader);
/* END HEADERS */

/* START REGISTRY DETAILS */
Vue.component(TimeslotPcGrid.name, TimeslotPcGrid);
Vue.component(AsignacionGrid.name, AsignacionGrid);
Vue.component(ConsultarealizadaGrid.name, ConsultarealizadaGrid);
Vue.component(LoterecibidoGrid.name, LoterecibidoGrid);
Vue.component(LotegeneradoGrid.name, LotegeneradoGrid);
Vue.component(PuntoCargaAptGrid.name, PuntoCargaAptGrid);
Vue.component(HistoricoCompGrid.name, HistoricoCompGrid);
Vue.component(HistoricoTermGrid.name, HistoricoTermGrid);
Vue.component(HistoricoLibTransTermcGrid.name, HistoricoLibTransTermcGrid);
Vue.component(HistoricoLibTransGrid.name, HistoricoLibTransGrid);
Vue.component(HistoricoLibTermcGrid.name, HistoricoLibTermcGrid);
Vue.component(LibramientoCompGrid.name, LibramientoCompGrid);
Vue.component(LibramientoTransGrid.name, LibramientoTransGrid);
Vue.component(HistoricoAccionTerminalGrid.name, HistoricoAccionTerminalGrid);
/* END DETAILS */

/* START REGISTRY DETAILS AUDIT */
Vue.component(LoteAuditGrid.name, LoteAuditGrid);
Vue.component(LibramientoAuditGrid.name, LibramientoAuditGrid);
Vue.component(ConsultaAuditGrid.name, ConsultaAuditGrid);
Vue.component(AsignacionAuditGrid.name, AsignacionAuditGrid);
Vue.component(HistoricoAuditGrid.name, HistoricoAuditGrid);
Vue.component(TimeslotAuditGrid.name, TimeslotAuditGrid);
Vue.component(TimeslotPlanifAuditGrid.name, TimeslotPlanifAuditGrid);
/* END DETAILS AUDIT */

/* START REGISTRY DETAILS FOR THE SELECT INFO BUTTONS */
Vue.component(DetailEncargoAuditForm.name, DetailEncargoAuditForm);
Vue.component(DetailConsultaForm.name, DetailConsultaForm);
Vue.component(DetailLoteForm.name, DetailLoteForm);
Vue.component(DetailEncargoTermForm.name, DetailEncargoTermForm);
Vue.component(DetailEncargoCompForm.name, DetailEncargoCompForm);
Vue.component(AuditAsignacionGrid.name, AuditAsignacionGrid);
Vue.component(AuditAsignacionForm.name, AuditAsignacionForm);
/* END DETAILS FOR THE SELECT INFO BUTTONS */

Vue.component(CamionForm.name, CamionForm);

Vue.component(AppLoginActions.name, AppLoginActions);

const puiRoutes = [
	{ path: '/', redirect: 'home' },
	{ path: 'home', component: () => import('@/components/appwelcome/AppWelcomePanel.vue') },
	{ path: 'usersettings', component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserSettingsForm') },
	{ path: 'puiaudit', component: () => import('pui9-admin/src/components/config/puiaudit/PuiAuditGrid') },
	{ path: 'session', component: () => import('pui9-admin/src/components/config/puisession/PuiSessionGrid') },
	{ path: 'puiuser', component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserGrid') },
	{ path: '(.*/)?puiuser/:method/:pk', props: true, component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserForm') },
	{ path: 'puiprofile', component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileGrid') },
	{ path: '(.*/)?puiprofile/:method/:pk', props: true, component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileForm') },
	{ path: 'puifunctionality', component: () => import('pui9-admin/src/components/admin/puifunctionality/PuiFunctionalityGrid') },
	{ path: 'puivariable', component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableGrid') },
	{ path: '(.*/)?puivariable/:method/:pk', props: true, component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableForm') },
	{ path: 'puilanguage', component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageGrid') },
	{ path: '(.*/)?puilanguage/:method/:pk', props: true, component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageForm') },
	{ path: 'puidocument', component: () => import('pui9-documents/src/components/PuiDocumentGrid') },
	{ path: '(.*/)?puidocument/:method/:pk', props: true, component: () => import('pui9-documents/src/components/PuiDocumentForm') },
	{ path: 'puidocgentemplate', component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateGrid') },
	{
		path: '(.*/)?puidocgentemplate/:method/:pk',
		props: true,
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateForm')
	}
];

const appRoutes = [
	{
		path: 'comprador',
		component: CompradorGrid
	},
	{
		path: '(.*/)?comprador/:method/:pk',
		props: true,
		component: CompradorForm
	},
	{
		path: 'terminal',
		component: TerminalGrid
	},
	{
		path: '(.*/)?terminal/:method/:pk',
		props: true,
		component: TerminalForm
	},
	{
		path: 'transportista',
		component: TransportistaGrid
	},
	{
		path: '(.*/)?transportista/:method/:pk',
		props: true,
		component: TransportistaForm
	},
	{
		path: 'mercancia',
		component: MercanciaGrid
	},
	{
		path: '(.*/)?mercancia/:method/:pk',
		props: true,
		component: MercanciaForm
	},
	{
		path: 'usuarioaplicacion',
		component: UsuarioAplicacionGrid
	},
	{
		path: '(.*/)?usuarioaplicacion/:method/:pk',
		props: true,
		component: UsuarioAplicacionForm
	},
	{
		path: 'usuarioaplicacionsettings',
		component: UsuarioAplicacionSettingsForm
	},
	{
		path: 'puntocarga',
		component: PuntoCargaGrid
	},
	{
		path: '(.*/)?puntocarga/:method/:pk',
		props: true,
		component: PuntoCargaForm
	},
	{
		path: 'motivoanulrech',
		component: MotivoAnulRechGrid
	},
	{
		path: '(.*/)?motivoanulrech/:method/:pk',
		props: true,
		component: MotivoAnulRechForm
	},
	{
		path: 'autorizacion',
		component: AutorizacionGrid
	},
	{
		path: '(.*/)?autorizacion/:method/:pk',
		props: true,
		component: AutorizacionForm
	},
	{
		path: 'encargocomp',
		component: EncargoCompGrid
	},
	{
		path: '(.*/)?encargocomp/:method/:pk',
		props: true,
		component: EncargoCompForm
	},
	{
		path: 'encargoterm',
		component: EncargoTermGrid
	},
	{
		path: '(.*/)?encargoterm/:method/:pk',
		props: true,
		component: EncargoTermForm
	},
	{
		path: 'consultarealizada',
		component: ConsultarealizadaGrid
	},
	{
		path: '(.*/)?consultarealizada/:method/:pk',
		props: true,
		component: ConsultarealizadaForm
	},
	{
		path: '(.*/)?loterecibido/:method/:pk',
		props: true,
		component: LoterecibidoForm
	},
	{
		path: 'consultarecibida',
		component: ConsultarecibidaGrid
	},
	{
		path: '(.*/)?consultarecibida/:method/:pk',
		props: true,
		component: ConsultarecibidaForm
	},
	{
		path: 'timeslotplanif',
		component: TimeslotPlanifGrid
	},
	{
		path: '(.*/)?timeslotplanif/:method/:pk',
		props: true,
		component: TimeslotPlanifForm
	},
	{
		path: 'timeslot',
		component: TimeslotGrid
	},
	{
		path: '(.*/)?timeslot/:method/:pk',
		props: true,
		component: TimeslotForm
	},
	{
		path: 'lotegenerado',
		component: LotegeneradoGrid
	},
	{
		path: '(.*/)?lotegenerado/:method/:pk',
		props: true,
		component: LotegeneradoForm
	},
	{
		path: 'asignacion',
		component: AsignacionGrid
	},
	{
		path: '(.*/)?asignacion/:method/:pk',
		props: true,
		component: AsignacionEncargoForm
	},
	{
		path: 'timeslotpc',
		component: TimeslotPcGrid
	},
	{
		path: '(.*/)?timeslotpc/:method/:pk',
		props: true,
		component: TimeslotPcForm
	},
	{
		path: 'asignaciontrans',
		component: AsignacionTransGrid
	},
	{
		path: '(.*/)?asignaciontrans/:method/:pk',
		props: true,
		component: AsignacionTransForm
	},
	{
		path: '(.*/)?asignaciontrans/:method/:pk/reasignacion/create/new/',
		props: true,
		component: ReAsignacionTransForm
	},
	{
		path: '(.*/)?asignaciontrans/:method/:pk/libramiento/create/new/',
		props: true,
		component: GenerarLibramientoTransForm
	},
	{
		path: 'usuariotransportista',
		component: UsuarioTransportistaGrid
	},
	{
		path: '(.*/)?usuariotransportista/:method/:pk',
		props: true,
		component: UsuarioTransportistaForm
	},
	{
		path: 'usuarioterminal',
		component: UsuarioTerminalGrid
	},
	{
		path: '(.*/)?usuarioterminal/:method/:pk',
		props: true,
		component: UsuarioTerminalForm
	},
	{
		path: 'usuariocomprador',
		component: UsuarioCompradorGrid
	},
	{
		path: '(.*/)?usuariocomprador/:method/:pk',
		props: true,
		component: UsuarioCompradorForm
	},
	{
		path: 'tipousuarioperfil',
		component: TipoUsuarioPerfilGrid
	},
	{
		path: '(.*/)?tipousuarioperfil/:method/:pk',
		props: true,
		component: TipoUsuarioPerfilForm
	},
	{
		path: 'libramientocomp',
		component: LibramientoCompGrid
	},
	{
		path: '(.*/)?libramientocomp/:method/:pk',
		props: true,
		component: LibramientoCompForm
	},
	{
		path: 'libramientotermg',
		component: LibramientoTermgGrid
	},
	{
		path: '(.*/)?libramientotermg/:method/:pk',
		props: true,
		component: LibramientoTermgForm
	},
	{
		path: 'libramientotermc',
		component: LibramientoTermcGrid
	},
	{
		path: '(.*/)?libramientotermc/:method/:pk',
		props: true,
		component: LibramientoTermcForm
	},
	{
		path: 'libramientotrans',
		component: LibramientoTransGrid
	},
	{
		path: '(.*/)?libramientotrans/:method/:pk',
		props: true,
		component: LibramientoTransForm
	},
	{
		path: 'puntocargaapt',
		component: PuntoCargaAptGrid
	},
	{
		path: '(.*/)?puntocargaapt/:method/:pk',
		props: true,
		component: PuntoCargaAptForm
	},
	{
		path: 'historicocomp',
		component: HistoricoCompGrid
	},
	{
		path: 'historicoterm',
		component: HistoricoTermGrid
	},
	{
		path: 'consultaaudit',
		component: ConsultaAuditGrid
	},
	{
		path: '(.*/)?consultaaudit/:method/:pk',
		props: true,
		component: ConsultaAuditForm
	},
	{
		path: 'loteaudit',
		component: LoteAuditGrid
	},
	{
		path: '(.*/)?loteaudit/:method/:pk',
		props: true,
		component: LoteAuditForm
	},
	{
		path: 'libramientoaudit',
		component: LibramientoAuditGrid
	},
	{
		path: '(.*/)?libramientoaudit/:method/:pk',
		props: true,
		component: LibramientoAuditForm
	},
	{
		path: 'encargoaudit',
		component: EncargoAuditGrid
	},
	{
		path: '(.*/)?encargoaudit/:method/:pk',
		props: true,
		component: EncargoAuditForm
	},
	{
		path: 'historicoaudit',
		component: HistoricoAuditGrid
	},
	{
		path: 'asignacionaudit',
		component: AsignacionAuditGrid
	},
	{
		path: '(.*/)?asignacionaudit/:method/:pk',
		props: true,
		component: AsignacionAuditForm
	},
	{
		path: 'historicolibtranstermc',
		component: HistoricoLibTransTermcGrid
	},
	{
		path: 'historicolibtrans',
		component: HistoricoLibTransGrid
	},
	{
		path: 'historicolibtermc',
		component: HistoricoLibTermcGrid
	},
	{
		path: 'timeslotaudit',
		component: TimeslotAuditGrid
	},
	{
		path: '(.*/)?timeslotaudit/:method/:pk',
		props: true,
		component: TimeslotAuditForm
	},
	{
		path: 'timeslotplanifaudit',
		component: TimeslotPlanifAuditGrid
	},
	{
		path: '(.*/)?timeslotplanifaudit/:method/:pk',
		props: true,
		component: TimeslotPlanifAuditForm
	},
	{
		path: 'documentacion',
		component: DocumentacionGrid
	},
	{
		path: '(.*/)?documentacion/:method/:pk',
		props: true,
		component: DocumentacionForm
	},
	{
		path: 'documentacioncab',
		component: DocumentacioncabGrid
	},
	{
		path: '(.*/)?documentacioncab/:method/:pk',
		props: true,
		component: DocumentacioncabForm
	},
	{
		path: 'tipodocumento',
		component: TipoDocumentoGrid
	},
	{
		path: '(.*/)?tipodocumento/:method/:pk',
		props: true,
		component: TipoDocumentoForm
	},
	{
		path: 'auditasignacion',
		component: AuditAsignacionGrid
	},
	{
		path: 'novedades',
		component: NovedadesGrid
	},
	{
		path: '(.*/)?novedades/:method/:pk',
		props: true,
		component: NovedadesForm
	},
	{
		path: 'camion',
		component: CamionGrid
	},
	{
		path: '(.*/)?camion/:method/:pk',
		props: true,
		component: CamionForm
	},
	{
		path: 'conductorbloqueado',
		component: ConductorbloqueadoGrid
	},
	{
		path: '(.*/)?conductorbloqueado/:method/:pk',
		props: true,
		component: ConductorbloqueadoForm
	},
	{
		path: 'historicoaccionterminal',
		component: HistoricoAccionTerminalGrid
	}
];

const puiNotFound = [{ path: '*', redirect: 'home' }];

function setAuthRequired(route) {
	return { ...route, meta: { ...route.meta, requiresAuth: true } };
}

const mergedRouter = {
	mode: 'hash',
	hash: true,
	routes: [
		{ path: '/login', component: () => import('pui9-base/src/components/PuiIntroLayout') },
		{
			path: '/resetPassword',
			component: () => import('pui9-base/src/components/PuiUserResetPassword')
		},
		{
			path: '/',
			component: () => import('pui9-base/src/components/PuiBaseLayout'),
			children: puiRoutes.concat(appRoutes).map(setAuthRequired).concat(puiNotFound)
		}
	]
};

export default new VueRouter(mergedRouter);
