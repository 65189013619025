<template>
	<div class="camion puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6>
				<pui-field :label="this.$t('vtccamiones.matricula')" :value="getMatricula"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'camionformheader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'camion'
		};
	},
	computed: {
		getMatricula() {
			return this.model && this.model.matricula ? this.model.matricula : '';
		}
	},
	methods: {
		extendFilter(filter) {
			// Ya que es una tabla con varias vistas para que funcione correctamente se necesita indicar el modelo
			filter.model = 'camion';
		}
	}
};
</script>
<style lang="postcss" scoped></style>
