<template>
	<v-layout wrap>
		<v-flex xs12 md6>
			<pui-select
				v-if="modelLoaded"
				attach="idtransportista"
				:label="$t('asignacion.idtransportista')"
				clearable
				:disabled="formDisabled || model.indlibramientos === '1'"
				v-model="model"
				required
				modelName="vluptransportistas"
				:itemsToSelect="[{ idtransportista: model.idtransportista }]"
				:modelFormMapping="{ idtransportista: 'idtransportista' }"
				:itemValue="['idtransportista']"
				:itemText="(item) => `${item.nombre}`"
				:key="'itrkey' + this.indice"
				:id="'idtr' + this.indice"
				:fixedFilter="fixedFilterTransportista"
				:order="{ nombre: 'asc' }"
			></pui-select>
		</v-flex>
		<v-flex xs12 md4>
			<pui-number-field
				:label="$t('asignacion.kilosasig')"
				v-model="model.pesoasignado"
				required
				decimals="0"
				max="8000000"
				:disabled="formDisabled || model.indlibramientos === '1'"
				:id="'pesottrans' + this.indice"
				suffix="Kg"
				:key="kilosasig"
			></pui-number-field>
		</v-flex>
		<v-flex xs1 md1 class="pointer" @click="removeTransportista()" v-show="indice > 0 && (!formDisabled || model.indlibramientos !== '1')">
			<v-list-item>
				<v-icon small class="trashIcon">fa fa-trash-alt</v-icon>
			</v-list-item>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			fixedFilterTransportista: {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'indbaja', op: 'eq', data: '0' }]
			},
			kilosasig: 0
		};
	},
	computed: {},
	watch: {},
	methods: {
		// override
		afterGetData() {},
		removeTransportista() {
			this.parentlist.splice(this.indice, 1);
		}
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		parentlist: {
			type: Array,
			required: true
		},
		indice: {
			type: Number,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		fromGrid: {
			type: Boolean,
			required: false,
			default: false
		}
	}
};
</script>
<style scoped></style>

<i18n>
{
    "en": {
		"asignacion.idtransportista": "Carrier",
		"asignacion.kilosasig": "Assigned kg"
    },
    "es": {
        "asignacion.idtransportista": "Transportista",
		"asignacion.kilosasig": "Kilos asignados"
    },
    "ca": {
        "asignacion.idtransportista": "Transportista",
		"asignacion.kilosasig": "Quilos assignats"
    }
}
</i18n>
