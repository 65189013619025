<template>
	<div class="pui-form">
		<libramiento-trans-modals :modelName="modelName"></libramiento-trans-modals>
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded && myModelLoaded">
			<libramientotransformheader :modelPk="parentModelPk" origen="LT"></libramientotransformheader>
		</pui-form-header>
		<v-form
			class="pui-libramientotransform mb-4 pb-4"
			ref="form"
			v-model="valid"
			lazy-validation
			@submit.prevent
			v-if="modelLoaded && myModelLoaded"
		>
			<v-layout v-if="isCreatingElement" class="ml-3 mb-3">
				<v-flex xs12>
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12 md8>
							<pui-select
								attach="idasignacion"
								:label="$t('libramientotrans.idasignacion')"
								clearable
								v-model="model"
								required
								modelName="vlupasignacion"
								:itemsToSelect="[{ idasignacion: model.idasignacion }]"
								:modelFormMapping="{ idasignacion: 'idasignacion' }"
								:itemValue="['idasignacion']"
								:id="'idasignacion'"
								:fixedFilter="fixedFilterAsignacion"
								:itemText="(item) => `${item.pesoasignado} Kg - ${item.mercancia} - ${getDateFormated(item.fecharecogida)}`"
							></pui-select>
						</v-flex>
					</v-layout>
					<div v-if="model.libramientos">
						<div v-for="(libramiento, index) in model.libramientos" :key="index">
							<generar-libramiento-trans-core-form
								:model="libramiento"
								:formDisabled="formDisabled"
								:modelLoaded="modelLoaded"
								:parentlist="model.libramientos"
								:indice="index"
								:method="method"
								:fixedFilterTimeslot="fixedFilterTimeslot"
								:fixedFilterConductor="fixedFilterConductor"
								:fixedFilterCamion="externalFilter"
								:parentModel="model"
							></generar-libramiento-trans-core-form>
						</div>
					</div>
					<v-layout wrap v-if="model.idasignacion">
						<v-flex xs12>
							<v-btn depressed color="secondary" class="elevation-0" @click.native="addLibramiento()">
								{{ this.$t('addLibramiento') }}
							</v-btn>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 style="height: 80px;"></v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<v-layout v-else>
				<v-flex xs12>
					<v-tabs v-model="tabmodel" class="ml-3 mb-3">
						<v-tab :key="0" :href="'#maintab'">{{ $t('libramientotrans.maintab') }}</v-tab>
						<v-tab :key="1" :href="'#historicolibtrans'" v-if="!isCreatingElement">{{ $t('libramientotrans.historico') }}</v-tab>
					</v-tabs>
					<v-tabs-items v-model="tabmodel" class="ml-4 mb-3">
						<v-tab-item :key="0" :value="`maintab`">
							<libramiento-form-base
								:model="model"
								:formDisabled="formDisabled"
								:modelLoaded="modelLoaded"
								tipoLibramiento="TS"
								:conductorFixedFilter="fixedFilterConductor"
							></libramiento-form-base>
						</v-tab-item>
						<v-tab-item :key="1" :value="`historicolibtrans`" lazy v-if="!isCreatingElement">
							<pui-master-detail
								componentName="historicolibtransgrid"
								:parentModelName="modelName"
								:parentPk="pk"
								:parentPkChildFk="{ idlibramiento: 'idlibramiento' }"
							></pui-master-detail>
						</v-tab-item>
					</v-tabs-items>
				</v-flex>
			</v-layout>
			<pui-form-footer>
				<v-layout v-if="isCreatingElement">
					<v-flex xs12>
						<v-layout>
							<v-flex xs12 align-self-center>
								<span class="labelCheck">
									<pui-checkbox v-model="aceptadasNormativa" :trueValue="1" :falseValue="0" class="userCheck"></pui-checkbox>
									{{ normativaSeguridadText }}
									<a :href="normativaSeguridadUrl" target="_blank">{{ $t('descargarDocumento') }}</a>
								</span>
							</v-flex>
						</v-layout>
						<v-layout>
							<v-flex xs12 align-self-center>
								<span class="labelCheck">
									<pui-checkbox v-model="aceptadasLimpieza" :trueValue="1" :falseValue="0" class="userCheck"></pui-checkbox>
									{{ seguridadAlimentariaText }}
								</span>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
				<v-layout>
					<v-flex xs12 sm12 md8 lg8 xl8>
						<v-layout>
							<v-flex xs12 md4>
								<!--<pui-form-mini-audit class="pl-2" :model="model" v-if="this.method !== 'create'"></pui-form-mini-audit>-->
							</v-flex>
							<v-flex xs12 md8>
								<v-layout justify-end fill-height>
									<v-btn v-if="!formDisabled" depressed color="secondary" class="elevation-0" @click.native="savevalidate()">
										{{ this.$t('form.save') }}
									</v-btn>
									<v-btn text outlined @click.native="back()">{{ $t('form.cancel') }}</v-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
		<pui-modal-dialog
			v-if="modals.cambioestado.show"
			:cancelText="$t('form.cancel')"
			:disableOk="modals.cambioestado.disableOk"
			:okText="$t('form.accept')"
			:messageText="$t('asignacion.modal.cambioestado.texto')"
			:titleText="modals.cambioestado.titleText"
			:dialogName="`cambioestado-${this.modelName}`"
			ref="cambioestado"
		></pui-modal-dialog>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import LibramientoFormBase from '../LibramientoFormBase';
import GenerarLibramientoTransCoreForm from '@/components/asignacion/GenerarLibramientoTransCoreForm';
import LibramientoTransActions from './LibramientoTransActions';
import LibramientoTransModals from './LibramientoTransModals';

export default {
	name: 'libramientotransform',
	mixins: [PuiFormMethodsMixin],
	components: { LibramientoFormBase, GenerarLibramientoTransCoreForm, LibramientoTransModals },
	data() {
		return {
			modelName: 'libramientotrans',
			actions: LibramientoTransActions.formactions,
			myModelLoaded: false,
			parentModelPk: null,
			matriculaold: null,
			remolqueold: null,
			idconductorold: null,
			Pesosolicitadoold: null,
			fixedFilterAsignacion: null,
			fixedFilterTimeslot: null,
			fixedFilterConductor: null,
			fixedFilterCamion: null,
			modals: {
				cambioestado: {
					disableOk: false,
					show: false,
					titleText: this.$t('asignacion.modal.cambioestado.title')
				}
			},
			tabmodel: 'maintab',
			aceptadasNormativa: 0,
			aceptadasLimpieza: 0,
			showSeguridadAlimentaria: false,
			showNormativaSeguridad: false,
			normativaSeguridadText: '',
			seguridadAlimentariaText: '',
			normativaSeguridadUrl: '',
			urlControllerSeg: '/libramientotrans/getSeguridadAlimentariaText',
			urlControllerNor: '/libramientotrans/getNormativaSeguridadText',
			urlControllerNorUrl: '/libramientotrans/getNormativaSeguridadUrl',
			pesodefecto: null
		};
	},
	methods: {
		// Override
		afterGetData() {
			this.pesodefecto = this.model.pesosolicitado;
			if (this.isCreatingElement) {
				this.fixedFilterAsignacion = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'idtransportista', op: 'eq', data: this.model.idtransportista },
						{ field: 'fecharecogida', op: 'gtt', data: -1 }
					]
				};
			} else {
				this.parentModelPk = { idencargo: this.model.idencargo };
				if (this.model.codestado !== 'LT' && this.model.codestado !== 'LS' && this.model.codestado !== 'LM') this.formDisabled = true;

				this.subscribeToModalDialogEvent();
				this.matriculaold = this.model.matricula;
				this.remolqueold = this.model.remolque;
				this.idconductorold = this.model.idconductor;
				this.pesosolicitadoold = this.model.pesosolicitado;
			}
			this.fixedFilterConductor = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'idtransportista', op: 'eq', data: this.model.idtransportista }]
			};

			this.myModelLoaded = true;
		},

		savevalidate() {
			if (this.isCreatingElement) {
				this.generateLib(false);
			} else if (
				(this.matriculaold !== this.model.matricula ||
					this.remolqueold !== this.model.remolque ||
					this.idconductorold !== this.model.idconductor ||
					Number(this.pesosolicitadoold) !== Number(this.model.pesosolicitado)) &&
				(this.model.codestado === 'LT' || this.model.codestado === 'LM')
			) {
				this.$puiEvents.$emit('pui-modalDialog-cambioestado-' + this.modelName + '-show', '');
				this.$puiEvents.$on('pui-action-runAction-' + this.modelName, () => {
					this.$puiEvents.$off('pui-action-runAction-' + this.modelName);
					this.save(false);
				});
			} else {
				this.save(false);
			}
		},
		subscribeToModalDialogEvent() {
			// Modal cambioestado
			this.$puiEvents.$on(`pui-modalDialog-cambioestado-${this.modelName}-ok`, () => {
				this.setShowCambioestado(false);
				this.$puiEvents.$emit('pui-action-runAction-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-cambioestado-${this.modelName}-cancel`, () => {
				this.setShowCambioestado(false);
				this.$puiEvents.$off('pui-action-runAction-' + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
			this.$puiEvents.$on(`pui-modalDialog-cambioestado-${this.modelName}-show`, () => {
				this.setShowCambioestado(true);
			});
		},
		setShowCambioestado(action) {
			this.modals.cambioestado.show = action;
		},
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		},
		addLibramiento() {
			this.model.libramientos.push({ pesosolicitado: this.pesodefecto });
		},
		generateLib(shouldStayInPage) {
			this.beforeSave && this.beforeSave();
			if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				return;
			}

			//Si no han aceptado las condiciones no se crean
			if (this.aceptadasNormativa === 0 || this.aceptadasLimpieza === 0) {
				this.$puiNotify.info(this.$t('libramiento.aceptadasCondiciones'));
				return;
			}

			this.generate(shouldStayInPage, '/asignaciontrans/generate');
		},
		getAssignData() {
			//			let asignacionUrl = '/asignaciontrans/get';
			//			this.$puiRequests.getRequest(
			//				asignacionUrl,
			//				{ idasignacion: this.model.idasignacion },
			//				response => {
			this.model.libramientos = [];
			this.model.libramientos.push({ pesosolicitado: this.pesodefecto });
			//					response.data.libramientos.forEach(libramiento => {
			//						this.model.libramientos.push(libramiento);
			//					});
			//				},
			//				error => {
			//					console.log(error);
			//				}
			//			);
		},
		generate(shouldStayInPage, url) {
			this.$puiRequests.postRequest(
				url + '?idasignacion=' + this.model.idasignacion,
				this.model.libramientos,
				(response) => {
					this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
					this.afterSave && this.afterSave(response.data);
					if (!(shouldStayInPage === true)) {
						this.back();
					}
				},
				(error) => {
					this.onSaveError && this.onSaveError();
					this.onError(error);
				}
			);
		},
		getSeguridadAlimentaria() {
			this.$puiRequests.getRequest(
				this.urlControllerSeg,
				null,
				(response) => {
					if (response.data) {
						this.seguridadAlimentariaText = response.data;
					}
				},
				(error) => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		getNormativaSeguridad() {
			this.$puiRequests.getRequest(
				this.urlControllerNor,
				null,
				(response) => {
					if (response.data) {
						this.normativaSeguridadText = response.data;
					}
				},
				(error) => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		getNormativaSeguridadUrl() {
			this.$puiRequests.getRequest(
				this.urlControllerNorUrl,
				null,
				(response) => {
					if (response.data) {
						this.normativaSeguridadUrl = response.data;
					}
				},
				(error) => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		}
	},
	watch: {
		'model.idasignacion'(newValue) {
			if (this.isCreatingElement && newValue != null) {
				this.fixedFilterTimeslot = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'idasignacion', op: 'eq', data: this.model.idasignacion }]
				};

				const params = { idasignacion: this.model.idasignacion };
				const thisModel = this.$store.getters.getModelByName('asignaciontrans');
				const urlController = thisModel.url.get;
				this.$puiRequests.getRequest(
					urlController,
					params,
					(response) => {
						this.fixedFilterConductor = {
							groups: [],
							groupOp: 'and',
							rules: [
								{ field: 'idtransportista', op: 'eq', data: this.model.idtransportista },
								{ field: 'idterminal', op: 'in', data: response.data.idterminal + ',' + response.data.idterminalcarga }
							]
						};

						this.getAssignData();
					},
					() => {
						const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ';
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.Model.name);
						var message = this.$puiI18n.t('puiaction.notifyError');
						this.$puiNotify.error(message, title);
					}
				);
			}
		}
	},
	destroyed() {
		this.$puiEvents.$off(`pui-modalDialog-cambioestado-${this.modelName}-ok`);
		this.$puiEvents.$off(`pui-modalDialog-cambioestado-${this.modelName}-cancel`);
		this.$puiEvents.$off(`pui-modalDialog-cambioestado-${this.modelName}-show`);
		this.$puiEvents.$off(`refreshForm-action-running-ended-${this.modelName}`);
	},

	created() {
		this.getSeguridadAlimentaria();
		this.getNormativaSeguridad();
		this.getNormativaSeguridadUrl();
	},
	mounted() {
		this.$puiEvents.$on(`refreshForm-action-running-ended-${this.modelName}`, () => {
			//Para hacer recarga de la pantalla del form al realizar una accion
			this.modelLoaded = false;
			this.onReady();
		});
		this.$puiEvents.$on('onPuiSelect_selectedItems-idasignacion', (newValAsignacion) => {
			var lupamodel = newValAsignacion.model;
			if (lupamodel !== undefined) {
				this.model.fecharecogida = lupamodel.fecharecogida;
			}
		});
	},
	computed: {
		externalFilter() {
			const baja0Filter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'indbaja', op: 'eq', data: '0' },
					{ field: 'idtransportista', op: 'eq', data: this.model.idtransportista }
				]
			};
			const baja1Filter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'indbaja', op: 'eq', data: '1' },
					{ field: 'fecbaja', op: 'gt', data: this.model.fecharecogida },
					{ field: 'idtransportista', op: 'eq', data: this.model.idtransportista }
				]
			};
			const fixedFilterCamion = { groups: [baja0Filter, baja1Filter], groupOp: 'or', rules: [] };

			return fixedFilterCamion;
		}
	}
};
</script>

<style lang="postcss">
.labelCheck {
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: -0.6px;
	color: var(--N-400);
	padding-top: 1px;
	display: inline-block;
	//position: relative;
	//left: -100px;
}
.userCheck {
	display: inline-block;
	//position: relative;
	//left: -100px;
}
</style>

<i18n>
{
    "en": {
		"libramientotrans.maintab": "Riddance",
		"libramientotrans.consultasrealizadas": "Inquiry performed",
		"libramientotrans.loterecibido": "Lots received",
		"asignacion.modal.cambioestado.title":"Warning",
		"asignacion.modal.cambioestado.texto":"When you modify an already authorized library, it will return to the Requested status for the loading terminal to authorize it. Do you want to continue with the modification?",
		"libramientotrans.historico": "History",
		"libramientotrans.idasignacion": "Assignment",
		"addLibramiento": "+"
    },
    "es": {
        "libramientotrans.maintab": "Libramiento",
		"libramientotrans.consultasrealizadas": "Consultas realizadas",
		"libramientotrans.loterecibido": "Lotes recibidos",
		"asignacion.modal.cambioestado.title":"Aviso",
		"asignacion.modal.cambioestado.texto":"Al modificar un libramiento ya autorizado este volverá al estado Solicitado para que la terminal de carga lo autorice. ¿Desea continuar con la modificación?",
		"libramientotrans.historico": "Histórico",
		"libramientotrans.idasignacion": "Asignación",
		"addLibramiento": "+",
		"libramiento.aceptadasCondiciones": "Debe aceptar las condiciones de seguridad para generar el libramiento",
		"descargarDocumento": "Descargar documento adjunto."
    },
    "ca": {
        "libramientotrans.maintab": "Lliurament",
		"libramientotrans.consultasrealizadas": "Consultes realitzades",
		"libramientotrans.loterecibido": "Lots rebuts",
		"asignacion.modal.cambioestado.title":"Avís",
		"asignacion.modal.cambioestado.texto":"En modificar un lliurament ja autoritzat aquest tornarà a l'estat Sol·licitat perquè la terminal de càrrega ho autoritzi. Voleu continuar amb la modificació?",
		"libramientotrans.historico": "Històric",
		"libramientotrans.idasignacion": "Assignació",
		"addLibramiento": "+",
		"libramiento.aceptadasCondiciones": "Ha d'acceptar les condicions de seguretat per a generar el deslliurament",
		"descargarDocumento": "Descarregar document adjunt."
    }
}
</i18n>
