import { render, staticRenderFns } from "./GenerarLibramientoTransForm.vue?vue&type=template&id=77f01d2b&"
import script from "./GenerarLibramientoTransForm.vue?vue&type=script&lang=js&"
export * from "./GenerarLibramientoTransForm.vue?vue&type=script&lang=js&"
import style0 from "./GenerarLibramientoTransForm.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./GenerarLibramientoTransForm.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFlex,VForm,VLayout})
