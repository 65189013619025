<template>
	<v-layout wrap>
		<v-flex xs12>
			<v-layout wrap v-if="esFormComp || esFormTermg">
				<v-flex xs12>
					<pui-select
						id="idencargo"
						:label="$t('libramiento.idencargo')"
						attach="idencargo"
						disabled
						v-model="model"
						modelName="vlupencargo"
						:itemsToSelect="[{ idencargo: model.idencargo }]"
						:modelFormMapping="{ idencargo: 'idencargo' }"
						labelColumnStyles="xs12 sm4 md2"
						valueColumnStyles="xs12 sm6 md4"
						:itemValue="['idencargo']"
						:itemText="(item) => `${getDateFormated(item.fecharecogida)} - ${item.mercancia} - ${item.contrato}`"
						:order="{ fecharecogida: 'asc' }"
						:detailModelName="getDetailModel"
						:detailComponentName="getDetail"
					></pui-select>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12>
					<pui-select
						attach="idestado"
						id="idestado"
						:label="$t('libramiento.idestado')"
						clearable
						:disabled="formDisabled || esFormTrans"
						v-model="model"
						required
						modelName="vlupestados"
						:itemsToSelect="[{ idestado: model.idestado }]"
						:modelFormMapping="{ idestado: 'idestado' }"
						:itemValue="['idestado']"
						:itemText="(item) => `${item.descestado}`"
						:order="{ descestado: 'asc' }"
						labelColumnStyles="xs12 sm4 md2"
						valueColumnStyles="xs12 sm6 md4"
					></pui-select>
				</v-flex>
			</v-layout>
			<v-layout wrap v-if="model.idmotivoanulrechazo !== null">
				<v-flex xs12>
					<pui-select
						id="idmotivoanulrechazo"
						attach="idmotivoanulrechazo"
						:label="$t('libramiento.idmotivoanulrechazo')"
						clearable
						:disabled="formDisabled || esFormTrans"
						v-model="model"
						modelName="vlupmotivoanulrech"
						:itemsToSelect="[{ idmotivo: model.idmotivoanulrechazo }]"
						:modelFormMapping="{ idmotivo: 'idmotivoanulrechazo' }"
						:itemValue="['idmotivo']"
						:itemText="(item) => `${item.descripcion}`"
						:order="{ descripcion: 'asc' }"
						labelColumnStyles="xs12 sm4 md2"
						valueColumnStyles="xs12 sm6 md4"
					></pui-select>
				</v-flex>
			</v-layout>
			<v-layout wrap v-if="model.obsrechazo !== null">
				<v-flex xs12>
					<pui-text-area
						:label="$t('libramiento.obsrechazo')"
						v-model="model.obsrechazo"
						disabled
						maxlength="300"
						labelColumnStyles="xs12 sm4 md2"
						valueColumnStyles="xs12 sm8 md10"
					></pui-text-area>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex :class="getIdtranspCols">
					<pui-select
						id="idtransportista"
						attach="idtransportista"
						:label="$t('libramiento.idtransportista')"
						clearable
						disabled
						v-model="model"
						modelName="vluptransportistas"
						:itemsToSelect="[{ idtransportista: model.idtransportista }]"
						:modelFormMapping="{ idtransportista: 'idtransportista' }"
						:itemValue="['idtransportista']"
						:itemText="(item) => `${item.nombre}`"
						:key="'itrkey' + this.indice"
						:order="{ nombre: 'asc' }"
						:labelColumnStyles="getIdtranspColsLab"
						:valueColumnStyles="getIdtranspColsVal"
					></pui-select>
				</v-flex>
				<v-flex xs12 md5 offset-md1 v-if="!esFormTermg && !esFormTermc">
					<pui-select
						id="idconductor"
						attach="idconductor"
						:label="$t('libramiento.idconductor')"
						clearable
						:disabled="formDisabled"
						v-model="model"
						modelName="vlupconductorlibramiento"
						:itemsToSelect="[{ usr: model.idconductor }]"
						:modelFormMapping="{ usr: 'idconductor' }"
						:itemValue="['usr']"
						:itemText="(item) => `${item.nombre} ${getMovilFormated(item.movilconductor)}`"
						filterId="idtransportista"
						:fixedFilter="conductorFixedFilter"
						:order="{ nombre: 'asc' }"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm8"
						:key="keyConductor"
					></pui-select>
				</v-flex>
				<v-flex xs12 md5 offset-md1 v-else-if="esFormTermc">
					<pui-select
						id="idconductor"
						attach="idconductor"
						:label="$t('libramiento.idconductor')"
						clearable
						:disabled="formDisabled"
						v-model="model"
						modelName="vlupconductorlibramiento"
						:itemsToSelect="[{ usr: model.idconductor }]"
						:modelFormMapping="{ usr: 'idconductor' }"
						:itemValue="['usr']"
						:itemText="(item) => `${item.nombre} ${getMovilFormated(item.movilconductor)}`"
						:order="{ nombre: 'asc' }"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm8"
					></pui-select>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12 md6 v-show="false">
					<pui-text-field
						id="matricula"
						:label="$t('libramiento.matricula')"
						v-model="model.matricula"
						maxlength="20"
						:disabled="formDisabled"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm8"
						:rules="matriculaRules"
						:key="matricKey"
					></pui-text-field>
				</v-flex>
				<v-flex xs12 md6>
					<pui-select
						attach="idcamion"
						:label="$t('libramiento.idcamion')"
						clearable
						:disabled="formDisabled"
						v-model="model"
						required
						modelName="vlupcamion"
						:itemsToSelect="[{ idcamion: model.idcamion }]"
						:modelFormMapping="{ idcamion: 'idcamion' }"
						:itemValue="['idcamion']"
						:itemText="(item) => `${getCamionText(item)}`"
						:id="'idcamion'"
						filterId="idtransportista"
						:filterMap="{ idtransportista: model.idtransportista }"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm8"
					></pui-select>
				</v-flex>
				<v-flex xs12 md5 offset-md1>
					<pui-text-field
						id="remolque"
						:label="$t('libramiento.remolque')"
						v-model="model.remolque"
						maxlength="20"
						:disabled="formDisabled"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm8"
					></pui-text-field>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12 md6>
					<pui-number-field
						id="pesosolicitado"
						:label="$t('libramiento.pesosolicitado')"
						v-model="model.pesosolicitado"
						decimals="0"
						max="9999999"
						:disabled="formDisabled"
						suffix="Kg"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm8"
					></pui-number-field>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12 md6>
					<pui-select
						id="idpuntocarga"
						attach="idpuntocarga"
						:label="getTerminalLabel"
						clearable
						v-model="model"
						:disabled="formDisabled || esFormTrans"
						modelName="vluppuntocarga"
						:itemsToSelect="[{ idpuntocarga: model.idpuntocarga }]"
						:modelFormMapping="{ idpuntocarga: 'idpuntocarga' }"
						:itemValue="['idpuntocarga']"
						:itemText="(item) => `${getTerminalText(item)}`"
						:order="{ descripcion: 'asc' }"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm8"
					></pui-select>
				</v-flex>
				<v-flex xs12 md5 offset-md1>
					<pui-checkbox
						:label="$t('libramiento.indpcmodificado')"
						v-model="model.indpcmodificado"
						true-value="1"
						false-value="0"
						:disabled="formDisabled || esFormTrans"
						labelColumnStyles="xs12 sm2"
						valueColumnStyles="xs12 sm6"
					></pui-checkbox>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12 md6>
					<pui-select
						id="idtimeslot"
						attach="idtimeslot"
						:label="$t('libramiento.idtimeslot')"
						clearable
						:disabled="formDisabled || esFormTrans"
						v-model="model"
						modelName="vluptimeslotlibramodify"
						:itemsToSelect="[{ idtimeslot: model.idtimeslot }]"
						:modelFormMapping="{ idtimeslot: 'idtimeslot' }"
						:itemValue="['idtimeslot']"
						:itemText="
							(item) =>
								`${getDateFormated(item.fecharecogida)} ` +
								$t('libramiento.de') +
								` ${item.horainicio} ` +
								$t('libramiento.a') +
								` ${item.horafin}`
						"
						:order="{ horainicio: 'asc' }"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm8"
					></pui-select>
				</v-flex>
				<v-flex xs12 md5 offset-md1>
					<pui-text-field
						id="horaautorizacion"
						:label="$t('libramiento.horaautorizacion')"
						v-model="model.horaautorizacion"
						maxlength="20"
						:disabled="formDisabled || esFormTrans"
						labelColumnStyles="xs12 sm4"
						valueColumnStyles="xs12 sm3 md3"
					></pui-text-field>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12>
					<pui-text-area
						:label="$t('libramiento.destinomercancia')"
						v-model="model.destinomercancia"
						:disabled="formDisabled"
						required
						maxlength="300"
						labelColumnStyles="xs12 sm4 md2"
						valueColumnStyles="xs12 sm8 md10"
					></pui-text-area>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12>
					<pui-text-area
						id="observaciones"
						:label="$t('libramiento.observaciones')"
						v-model="model.observaciones"
						:disabled="formDisabled"
						maxlength="300"
						labelColumnStyles="xs12 sm4 md2"
						valueColumnStyles="xs12 sm8 md10"
					></pui-text-area>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12>
					<pui-field-set :title="$t('libramiento.section.infentrega')">
						<v-layout wrap>
							<v-flex xs12>
								<pui-date-field
									id="fecharecogida"
									:label="$t('libramiento.fecharecogida')"
									v-model="model.fecharecogida"
									:disabled="formDisabled || esFormTrans"
									timesecs
									labelColumnStyles="xs6 sm2"
									valueColumnStyles="xs12 sm8 md4"
									attach="fecharecogida"
								></pui-date-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12>
								<pui-text-area
									id="obsrecogida"
									:label="$t('libramiento.obsrecogida')"
									v-model="model.obsrecogida"
									:disabled="formDisabled || esFormTrans"
									maxlength="300"
									labelColumnStyles="xs6 sm2"
									valueColumnStyles="xs12 sm10"
								></pui-text-area>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			keyConductor: 0,
			matriculaRules: [(v) => this.validateMatriculaRules(v) || this.$t('libramiento.validate.matricula')],
			indice: 0,
			matricKey: 0
		};
	},
	methods: {
		getDateFormated(date) {
			return this.$dateTimeUtils.formatDate(date, this.$store.getters.dateFormat);
		},
		getTerminalText(item) {
			let text = '';
			if (this.mostrarTerminalCarga) text = text + item.terminal;
			if (this.mostrarPuntoCarga) text = text + (this.mostrarTerminalCarga ? ' / ' : '') + item.codigo + ' - ' + item.descripcion;
			return text;
		},
		getCamionText(item) {
			let text = '';
			text = item.matricula + (item.conductor != null ? ' - ' + item.conductor : '') + ' - ' + this.formatNumber(item.capacidad) + ' Kg';
			return text;
		},
		getMovilFormated(movil) {
			return movil === null ? '' : ' - ' + movil;
		},
		validateMatriculaRules(v) {
			var re = /^[a-z0-9A-Z]*$/;
			return re.test(v);
		},
		formatNumber(val) {
			if (val === null) {
				return '';
			}
			const thousandSeparator = this.$store.getters.thousandSeparator;
			const decimalSeparator = this.$store.getters.decimalSeparator;
			if (val.toString().includes('.')) {
				const parts = val.toString().split('.');
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
				return parts.join(decimalSeparator);
			}
			return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
		}
	},
	computed: {
		esFormComp() {
			return this.tipoLibramiento === 'C';
		},
		esFormTermg() {
			return this.tipoLibramiento === 'TG';
		},
		esFormTrans() {
			return this.tipoLibramiento === 'TS';
		},
		esFormTermc() {
			return this.tipoLibramiento === 'TC';
		},
		esPuntoCarga() {
			return this.model.codestado === 'LT' || this.model.codestado === 'LM' || this.model.codestado === 'LE' || this.model.codestado === 'LN';
		},
		mostrarTerminalCarga() {
			return this.esFormComp || this.esFormTrans || this.esFormTermc || (this.esFormTermg && this.esPuntoCarga);
		},
		mostrarPuntoCarga() {
			return ((this.esFormComp || this.esFormTrans) && this.esPuntoCarga) || this.esFormTermc;
		},
		getIdtranspCols: function () {
			return 'xs12 md' + (!this.esFormTermg ? '6' : '12');
		},
		getIdtranspColsLab: function () {
			return 'xs12 sm4 md' + (!this.esFormTermg ? '4' : '2');
		},
		getIdtranspColsVal: function () {
			return 'xs12 sm8 md' + (!this.esFormTermg ? '8' : '4');
		},
		getTerminalLabel() {
			if (this.esFormTermg) {
				return this.$t('libramiento.idterminal');
			} else {
				return this.$t('libramiento.idpuntocarga');
			}
		},
		getDetail() {
			if (this.esFormTermg) {
				return 'detailencargotermform';
			} else if (this.esFormComp) {
				return 'detailencargocompform';
			} else {
				return '';
			}
		},
		getDetailModel() {
			if (this.esFormTermg) {
				return 'encargoterm';
			} else if (this.esFormComp) {
				return 'encargocomp';
			} else {
				return '';
			}
		}
	},
	watch: {
		model() {
			this.keyConductor += 1;
		}
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		tipoLibramiento: {
			type: String,
			required: true
		},
		conductorFixedFilter: {
			type: Object,
			required: false
		},
		modelLoaded: {
			type: Boolean,
			required: true
		}
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-idcamion', (newValCamion) => {
			var lupamodel = newValCamion.model;
			if (lupamodel !== undefined) {
				this.model.matricula = lupamodel.matricula;
				this.matricKey++;
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-idcamion');
	}
};
</script>

<i18n>
{
    "en": {
		"libramiento.idestado": "State",
		"libramiento.idmotivoanulrechazo": "Reason for rejection/cancellation",
		"libramiento.idtransportista": "Transportista",
		"libramiento.idconductor": "Driver",
		"libramiento.matricula": "Plate",
		"libramiento.remolque": "Remolque",
		"libramiento.pesosolicitado": "Weight (Kg)",
		"libramiento.indpcmodificado": "Modified load point",
		"libramiento.idterminal": "Loading terminal",
		"libramiento.idpuntocarga": "Terminal and loading point",
		"libramiento.idtimeslot": "Time Slot for pick-up",
		"libramiento.horaautorizacion": "Authorized Time",
		"libramiento.observaciones": "Driver observations",
		"libramiento.fecharecogida": "Pickup date",
		"libramiento.obsrecogida": "Pickup observations",
		"libramiento.section.infentrega": "Delivery Information",
		"libramiento.de": "from",
		"libramiento.a": "to",
		"libramiento.idencargo": "Order"
    },
    "es": {
		"libramiento.idestado": "Estado",
		"libramiento.idmotivoanulrechazo": "Motivo rechazo/anulación",
		"libramiento.idtransportista": "Transportista",
		"libramiento.idconductor": "Conductor",
		"libramiento.matricula": "Matrícula",
		"libramiento.remolque": "Remolque",
		"libramiento.pesosolicitado": "Peso (Kg)",
		"libramiento.indpcmodificado": "Punto de carga modificado",
		"libramiento.idterminal": "Terminal de carga",
		"libramiento.idpuntocarga": "Terminal y punto de carga",
		"libramiento.idtimeslot": "Time Slot de recogida",
		"libramiento.horaautorizacion": "Hora autorizada",
		"libramiento.observaciones": "Observaciones del transportista",
		"libramiento.fecharecogida": "Fecha de recogida",
		"libramiento.obsrecogida": "Observaciones de recogida",
		"libramiento.section.infentrega": "Información entrega",
		"libramiento.de": "de",
		"libramiento.a": "a",
		"libramiento.idencargo": "Encargo"
    },
    "ca": {
		"libramiento.idestado": "Estat",
		"libramiento.idmotivoanulrechazo": "Motiu rebuig/anul·lació",
		"libramiento.idtransportista": "Transportista",
		"libramiento.idconductor": "Conductor",
		"libramiento.matricula": "Matrícula",
		"libramiento.remolque": "Remolc",
		"libramiento.pesosolicitado": "Pes (Kg)",
		"libramiento.indpcmodificado": "Punt de càrrega modificat",
		"libramiento.idterminal": "Terminal de càrrega",
		"libramiento.idpuntocarga": "Terminal i punt de càrrega",
		"libramiento.idtimeslot": "Time Slot de recollida",
		"libramiento.horaautorizacion": "Hora autoritzada",
		"libramiento.observaciones": "Observacions del transportista",
		"libramiento.fecharecogida": "Data de recollida",
		"libramiento.obsrecogida": "Observacions de recollida",
		"libramiento.section.infentrega": "Informació lliurament",
		"libramiento.de": "de",
		"libramiento.a": "a",
		"libramiento.idencargo": "Encàrrec"
    }
}
</i18n>
